import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    // Initialize paginator labels and translations
    this.getPaginatorIntl();
  }

  // Override the range label method
  rangeLabel = (page: number, pageSize: number, length: number) => {
    let off = this.translateText('paginator.off');
    let to = this.translateText('paginator.to');
    let entries = this.translateText('paginator.entries');
    let showing = this.translateText('paginator.showing');
    let totalPages = this.translateText('paginator.totalPages');

    // Get the current language
    const lang = this.translate.currentLang;

    if (length == 0 || pageSize == 0) {
      return `0 ${off} ${length}`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const pageCount = Math.ceil(length / pageSize);

    // If the start index exceeds the list length.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    // Return range label with appropriate translations

    if (lang == 'en') {
      return `${showing} ${
        startIndex + 1
      } ${to} ${endIndex} ${off} ${length} ${entries} - ${totalPages} ${pageCount}`;
    } else {
      return `${totalPages} ${pageCount} - ${showing} ${
        startIndex + 1
      } ${to} ${endIndex} ${off} ${length} ${entries}`;
    }
  };

  // Override the MatPaginatorIntl properties with translated labels

  getPaginatorIntl() {
    // Set the labels and translations
    this.firstPageLabel = this.translateText('paginator.firstPageLabel');
    this.itemsPerPageLabel = this.translateText('paginator.itemsPerPageLabel');
    this.nextPageLabel = this.translateText('paginator.nextPageLabel');
    this.previousPageLabel = this.translateText('paginator.previousPageLabel');
    this.lastPageLabel = this.translateText('paginator.lastPageLabel');
    this.getRangeLabel = this.rangeLabel;
    this.changes.next();
  }

  translateText(title: string) {
    return this.translate.instant(title);
  }
}
