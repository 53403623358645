import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ModalData {
  title?: string;
  content?: string;
  confirmBtnText?: string;
  data?: any;
  cancelBtnText?: string
  inboxList?: [];
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  translationPrefix: string = `general.`;

  configSave = {
    type: 'button',
    style: 'primary',
    size: 'large',
  };

  configClose = {
    type: 'button',
    style: 'primaryStroked',
    size: 'large',
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) { }

  close(): void {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(true);
  }
}
