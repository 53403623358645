
<div class="d-flex justify-content-between align-items-center border-bottom pb-1">
  <p class="m-0" mat-dialog-title>{{ data.title }}</p>
  <custom-icon title="{{'general.close' | translate }}" [iconName]="'close'" (click)="onNoClick()"></custom-icon>
</div>
<hr>
<div class="container-fluid custom-dialog">
    <div class="row">
      <!-- <div class="col-md-12 px-0">
        <p>{{ data.message }}</p>
      </div> -->
      <div class="custom-alert--modify mt-0">
        <custom-alert
          [alertMsg]="'general.columnsToDisplay' | translate"
        ></custom-alert>
      </div>
      <div class="col-md-3 px-0 mb-3" *ngFor="let col of tableColumns">
        <mat-checkbox [(ngModel)]="col.show" (change)="onColumnChange()">{{
          col.name
        }}</mat-checkbox>
      </div>

      <div class="col-md-12">
        <p
        class="fw-bold text-danger m-0 d-block w-100 mt-0 mb-4 text-center"
        *ngIf="
          disabledBtn && (isLists || isInfluencer || isTeamwork || isVoter || isSupplies || isWork)
        "
      >
        {{ translationPrefix + "customInfluencerText" | translate }}
      </p>
        <p class="text-center text-danger m-0" *ngIf="disabledBtn && (isOffice || isCandidate || isAllCandidates || isVehicles || isSupport || isHouse)">
      {{ translationPrefix + "customTeamworkText" | translate }}
    </p>
    <p class="text-center text-danger m-0" *ngIf="disabledBtn && (isTaskName || isMyNotes)">
      {{ translationPrefix + "customTeamworkText2" | translate }}
    </p>
    <p class="text-center text-danger m-0" *ngIf="disabledBtn && (isAssignedSupport)">
      {{ translationPrefix + "customTeamworkText5" | translate }}
    </p>
      </div>
      <div class="custom-alert--modify">
        <custom-alert
          [alertMsg]="'general.dragColumns' | translate"
        ></custom-alert>
      </div>
      <div class="col-md-12 px-0">
        <app-custom-table
          [tableColumns]="tableColumns"
          [tableData]="tableData"
          [dragEnabled]="true"
          [defaultPageSize]="1"
          [isPageableTop]="true"
          [enableSorting]="false"
          (sortedColumn)="onSortedColumn($event)"
        >
        </app-custom-table>
      </div>
</div>
<div mat-dialog-actions>
  <!-- <button
    mat-raised-button
    color="primary"
    cdkFocusInitial
    (click)="selectItem()"
    [disabled]="disabledBtn"
  >
    Save
  </button> -->
  <!-- <button mat-raised-button color="warn" (click)="onNoClick()">Close</button> -->
  <custom-button
    [buttonTxt]="translationPrefix + 'save' | translate"
    [configurations]="configSave"
    (click)="selectItem()"
    [disabled]="disabledBtn"
  ></custom-button>
  <custom-button
    [buttonTxt]="translationPrefix + 'close' | translate"
    [configurations]="configClose"
    (click)="onNoClick()"
  ></custom-button>
</div>
    </div>
