<div class="d-flex justify-content-between align-items-center border-bottom pb-1">
  <p class="m-0" mat-dialog-title>{{'login.confirm' | translate}}</p>
  <custom-icon [iconName]="'close'" (click)="close()"></custom-icon>
</div>

<hr>

<div mat-dialog-content>
  <p class="mt-5 mb-5 fw-bold">{{ data.title }}</p>
</div>

<div mat-dialog-actions class="footer-modal">
  <!-- <button mat-raised-button color="warn" cdkFocusInitial (click)="onConfirm()">
    {{ data.confirmBtnText ?? translationPrefix + "delete" | translate }}
  </button>
  <button mat-raised-button color="default" (click)="close()">
    {{ translationPrefix + "no" | translate }}
  </button> -->

  <custom-button [buttonTxt]="
      data.confirmBtnText ?? translationPrefix + 'delete' | translate" [configurations]="configSave"
    (click)="onConfirm()"></custom-button>
  <custom-button [buttonTxt]="data.cancelBtnText ?? translationPrefix + 'no' | translate" [configurations]="configClose"
    (click)="close()"></custom-button>
</div>