export interface TableColumn {
  name: string; // column name
  dataKey: string; // name of key of the actual data in this column
  position?: 'right' | 'left'; // should it be right-aligned or left-aligned?
  isSortable?: boolean; // can a column be sorted?
  isSearchable?: boolean; //can a column be searched?
  isFilterable?: boolean; // can a column be filtered?
  show?: boolean; // show or hide custom column
  iconColor?:string;
  filterBefore?:boolean;
  iconName?:string;
  classNameKey?:any;
  headerIcon?:string;
  headerIconColor?:string;
  justifyContent?:boolean;
  headerButton?: boolean;
  headerTooltip? : any;
  headerButtonText? : string;
  withIcon?: boolean,
  width?:string,
  filter?: {
    type: number | any;
    value?: string | any;
    labelEn? : string | any;
    labelAr?: string | any;
    formControl? : string | any;
    options?: FilterOption[] | any;
    show?: boolean; // enable filter card
  };
  // checkbox?: boolean; // represents if the column should have a checkbox
}

export interface EventColumn {
  row: Object;
  actionName: string;
}

interface FilterOption {
  id: number | string;
  en: string;
  ar:string;
}

export interface FilterParam {
  key: string;
  type: TableFilterType
}

export interface TransformedFilterParam extends FilterParam {
  value: any;
}

export interface TableFilterOptions {
  condition: "AND" | "OR";
}


export enum TableFilterType {
  dropDown = 1,
  text = 2,
  number = 3,
  multiSelect = 4,
  global=5,
  date=6
}
