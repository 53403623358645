import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dataPropertyGetter',
})
export class DataPropertyGetterPipe implements PipeTransform {
  transform(object: any, keyName: string, isToolTip: boolean = false, ...args: unknown[]): any {
    const value = object[keyName];

    // if (value instanceof Date) {
    //   return moment(value).format('MM/DD/YYYY');
    // }

    if (keyName === 'supplyDate') {
      return moment(value).format('DD/MM/YYYY');
    }

    if (object.seatType === 'other') {
      return (object.seatType = object.otherSeatType);
    }

    return value

    // if (value?.length > 26) {
    //   return isToolTip ? value : value.substring(0, 26) + '...';
    // } else {
    //   return isToolTip ? "" : value;
    // }
  }
}
