import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { TranslationKeys } from '@core/enums/translation-keys.enum';
import { TranslationService } from '@core/services/translate.service';

import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { GlobalService } from '@core/services/global.service';
import { GoogleMapsLoaderService } from '@core/services/googlemapsLoader.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@Component({
  selector: 'app-root',
  template: `
    <section appRtl>
      <router-outlet></router-outlet>
      <app-loader></app-loader>
    </section>
  `,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en', multi: true },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    //format: MAT_MOMENT_DATE_FORMATS

    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [MAT_DATE_LOCALE],
    // },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AppComponent implements OnInit {
  /** variable the store value after get it from
   *  default language from local storage on app start up */
  defaultLang = TranslationService.getLangFromLocalStorage();

  constructor(
    private googleMapsLoader: GoogleMapsLoaderService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject('window') private _window: Window,
    private translationService: TranslationService,
    private dateAdapter: DateAdapter<Date>,
    private globalService: GlobalService
  ) {
    this.translate.addLangs(['en', 'ar']);
    this.translate.setDefaultLang(String(this.defaultLang));
    this.changeLanguage(this.defaultLang);

    this.googleMapsLoader.load().then(() => {
      console.log('Google Maps API loaded');
    }).catch(error => {
      console.error('Error loading Google Maps API', error);
    });
  }

  /** @description this method is used to toggle between languages
   *
   */
  changeLanguage(lang?: any) {
    this._window.sessionStorage.setItem(TranslationKeys.TRANSLATION_KEY, lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    ////////////////////////////////////

    if (lang === 'ar') {
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      this.translationService.setAppDefaultLang(lang);
      // this.dateAdapter.setLocale('ar');
      this.document.getElementById('htmlParent')?.setAttribute('dir', 'rtl');
      this.document.getElementById('htmlParent')?.setAttribute('lang', 'ar');
      // this.document.getElementById('theme').setAttribute('href', 'assets/bootstrap-rtl/bootstrap-rtl.min.css');
      // this.changeFontOnLanguageChange(lang);
    } else {
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      this.translationService.setAppDefaultLang(lang);
      // this.dateAdapter.setLocale('en');
      // this.document.getElementById('theme')setAttribute('href', 'assets/bootstrap/css/bootstrap.min.css');
      // this.document.getElementById('theme').setAttribute('href', '');
      this.document.getElementById('htmlParent')?.setAttribute('dir', 'ltr');
      this.document.getElementById('htmlParent')?.setAttribute('lang', 'en');
      // this.document.getElementById('appRootBody').style.fontFamily ='Roboto, sans-serif ';
    }
  }

  ngOnInit(): void {
    !this.globalService.country && this.globalService.getCountry();
  }
}
