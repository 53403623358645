import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';

@Injectable({
  providedIn: 'root'
})

export class SystemService {

  constructor(
    private dialog: MatDialog,
    private _translateService: TranslateService,
  ) { }

  sanitizeObjectValues(object: { [key: string]: any }): { [key: string]: any } {

    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const value = object[key];

        if (!value && value != 0) delete object[key];
      }
    }

    return object;
  }

  confirmationDialog(settings?: {
    title?: string;
    confirmButtonTxt?: string;
    cancelBtnText?: string;
  }) {

    let dialogRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: '40vw',
      data: {
        title: settings?.title || this._translateService.instant('login.confirm'),
        confirmBtnText: settings?.confirmButtonTxt || this._translateService.instant('general.yes'),
        cancelBtnText: settings?.cancelBtnText || this._translateService.instant('general.no')
      },
    });

    return dialogRef;
  }
}



