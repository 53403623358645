import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { IsNumberPipe } from './isNumber.pipe';
import { FilterPipe } from './filter.pipe';

@NgModule({
  declarations: [
    SafePipe,
    IsNumberPipe,
    FilterPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    IsNumberPipe,
    FilterPipe,
  ]
})
export class PipesModule {}
