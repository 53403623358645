import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/services';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Location } from '@core/enums/location.enum';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  locationSubject = new BehaviorSubject<{
    latitude: number;
    longitude: number;
  }>({ latitude: 31.39025134990522, longitude: 36.368229788940845 });
  public logoutSubject = new BehaviorSubject<boolean>(false);
  public ShowDistrictField = new BehaviorSubject<boolean>(true);
  public regionId$ = new BehaviorSubject(null);
  public neighborhoodId$ = new BehaviorSubject(null);
  public electoralDistrictArr$ = new BehaviorSubject<any>([]);
  public isCampaignCreator$ = new BehaviorSubject<boolean>(false);
  public isDeliveryTasksCommitteeAdmin$ = new BehaviorSubject<boolean>(false);
  private _isFormRequestCommitteeAdmin = false;
  private _isMaterialRequestCommitteeAdmin = false;
  public updateCampaignSlogan$ = new BehaviorSubject<string>('');
  public campaignName$ = new BehaviorSubject<string>('');
  public userInfo$ = new BehaviorSubject<{ name: string; imageUrl: string }>({
    name: '',
    imageUrl: '',
  });

  country: { phoneNumberDigits: string; phoneKey: string };
  constructor(
    private _translateService: TranslateService,
    private titleService: Title,
    private apiService: ApiService,
    private http: HttpClient
  ) {}

  setTitle(title: any): void {
    const sub = this._translateService.get(title).subscribe(translation => {
      this.titleService.setTitle(translation);
    });
    sub.unsubscribe();
  }

  logout() {
    this.logoutSubject.next(true);
  }

  updateLocation(location: Location) {
    this.locationSubject.next(location);
  }

  getElectoralCircles() {
    return this.apiService.get('campaign/campaignMembers/getMemberDistricts');
  }

  getInitialElectoralCircles(campaignId: any) {
    return this.apiService.get(
      `campaign/campaignMembers/getInitialMemberDistricts/${campaignId}`
    );
  }

  getLocationObservable() {
    return this.locationSubject.asObservable();
  }

  getCountryInfo(countryCode: any) {
    return this.http.get(
      `${environment.apiUrlPrefix}campaign/public/getCountry/${countryCode}`
    );
  }

  async getCountry() {
    this.http
      .get(
        `${environment.IP_INFO_URL}/country?token=${environment.IP_INFO_TOKEN}`,
        { responseType: 'text' }
      )
      .pipe(
        switchMap(data => {
          return this.getCountryInfo(data);
        })
      )
      .subscribe((res: any) => (this.country = res.Data));
  }

  navigateToMainWebSite() {
    window.open('https://www.sawtak-me.com/', '_blank'); //! URL must be added in environment files
  }

  public get isFormRequestCommitteeAdmin(): boolean {
    return this._isFormRequestCommitteeAdmin;
  }

  public set isFormRequestCommitteeAdmin(value: boolean) {
    this._isFormRequestCommitteeAdmin = value;
  }

  public get isMaterialRequestCommitteeAdmin(): boolean {
    return this._isMaterialRequestCommitteeAdmin;
  }

  public set isMaterialRequestCommitteeAdmin(value: boolean) {
    this._isMaterialRequestCommitteeAdmin = value;
  }
}
