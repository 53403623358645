
<ng-container *ngIf="!content.filterBefore">
    <span class="filter-icon-container" [ngClass]="{'filter-value': controlHasValue}">
        <mat-icon [matMenuTriggerFor]=" filterMenu" [matMenuTriggerData]="{content}" [ngStyle]="{ color: content.iconColor, top: content.topValue}"
            class="cursor-pointer align-self-center filter-icon material-icons pointer filter-icon-custom position-absolute">{{content.iconName ? content.iconName : 'filter_alt'}}</mat-icon>
    </span>
</ng-container>

<ng-container *ngIf="content.filterBefore">
    <span class="filter-icon-container-before" [ngClass]="{'filter-value': controlHasValue}">
        <mat-icon [matMenuTriggerFor]=" filterMenu" [matMenuTriggerData]="{content}" [ngStyle]="{ color: content.iconColor, top: content.topValue}"
            class="cursor-pointer align-self-center filter-icon material-icons pointer filter-icon-custom position-absolute">{{content.iconName ? content.iconName : 'filter_alt'}}</mat-icon>
    </span>
</ng-container>


    <mat-menu class="heder-controls" #filterMenu="matMenu">

        <ng-template matMenuContent let-content="content">
            <div class="p-2">
                <ng-container [ngSwitch]="content.type">

                    <ng-container *ngSwitchCase="tableFilterType.text">

                        <mat-form-field (click)="$event.stopPropagation()" class="text-field-no-icon-extra-small text-filter"
                        appearance="outline">
                            <input  (input)="columnTextSearch($event)" matInput [formControl]="content.control" placeholder="{{ lang === 'ar' ? content.labelAr : content.labelEn }}">
                        </mat-form-field>

                        <div class="clear-filter">
                            <button mat-flat-button class="clear-filter__button" (click)="content.control.patchValue('');clearFilter()">
                                <span class="clear-filter__button-text">{{ "general.cancel" | translate }}</span>
                                <span class="material-icons">close</span>
                              </button>
                        </div>

                    </ng-container>


                    <ng-container *ngSwitchCase="tableFilterType.dropDown">
                        <mat-form-field (click)="$event.stopPropagation()" class="text-field-no-icon-extra-small filter-select"
                        appearance="outline">
                            <mat-select [formControl]="content.control" disableOptionCentering panelClass="filterPanel" placeholder="{{ lang === 'ar' ? content.labelAr : content.labelEn }}">
                                <span [hidden]="content.data?.length < 11" class="single-select-span-element">
                                    <mat-option class="single-select-option">
                                        <input matInput placeholder="{{ lang === 'ar' ? 'بحث' : 'Search' }}"
                                        class="single-select-search-input"
                                        [(ngModel)]="searchValue"
                                        (click)="$event.stopPropagation()"
                                        (keydown.enter)="$event.stopPropagation()"
                                        (keydown.space)="$event.stopPropagation()"
                                        (keyup)="filterOptions($event, content.data)"/>
                                    </mat-option>
                                </span>
                                <mat-option (click)="selectOption('')">{{ lang === 'ar' ? 'الكل' : 'All' }}</mat-option>
                                <mat-option *ngFor="let row of filteredData" [value]="row.id" (click)="selectOption(row)">
                                    {{ row.en }}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>

                        <!-- <div class="clear-filter">
                            <button mat-flat-button class="clear-filter__button" (click)="selectOption('');content.control.patchValue('')">
                                <span class="clear-filter__button-text">{{ "general.cancel" | translate }}</span>
                                <span class="material-icons">close</span>
                              </button>
                        </div> -->

                    </ng-container>

                    
                    <ng-container *ngSwitchCase="tableFilterType.multiSelect">
                        <mat-form-field (click)="$event.stopPropagation()" class="text-field-no-icon-extra-small filter-select"
                        appearance="outline">
                            <mat-select [matTooltip]="content.control.value" (selectionChange)="multiFilter($event)" #select multiple [formControl]="content.control" disableOptionCentering panelClass="filterPanel" placeholder="{{ lang === 'ar' ? content.labelAr : content.labelEn }}">
                                <span [hidden]="content.data?.length < 11" class="single-select-span-element">
                                    <mat-option class="single-select-option multi-selection-filter-search">
                                        <input matInput placeholder="{{ lang === 'ar' ? 'بحث' : 'Search' }}"
                                        class="single-select-search-input"
                                        [(ngModel)]="searchValue"
                                        (click)="$event.stopPropagation()"
                                        (keydown.enter)="$event.stopPropagation()"
                                        (keydown.space)="$event.stopPropagation()"
                                        (keyup)="filterOptions($event, content.data)"/>
                                    </mat-option>
                                </span>
                                <mat-option *ngFor="let row of filteredData" [value]="row.id">
                                    {{ row.en }}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>

                        <!-- <div class="clear-filter">
                            <button mat-flat-button class="clear-filter__button" (click)="selectOption('');content.control.patchValue('')">
                                <span class="clear-filter__button-text">{{ "general.cancel" | translate }}</span>
                                <span class="material-icons">close</span>
                              </button>
                        </div> -->

                    </ng-container>

                    <ng-container *ngSwitchCase="tableFilterType.date">

                        <mat-form-field (click)="$event.stopPropagation()" class="date-picker-filter text-field-no-icon-extra-small" appearance="outline">
                            <input (input)="columnTextSearch($event)" [formControl]="content.control" matInput [matDatepicker]="picker" placeholder="{{ lang === 'ar' ? content.labelAr : content.labelEn }}">
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-icon class="cursor-pointer date-picker-filter-toggle" matSuffix (click)="picker.open()">event</mat-icon>
                        </mat-form-field>
                        <div class="clear-filter">
                            <button mat-flat-button class="clear-filter__button" (click)="content.control.patchValue(null)">
                                <span class="clear-filter__button-text">{{ "general.cancel" | translate }}</span>
                                <span class="material-icons">close</span>
                              </button>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>


                    </ng-container>
                </ng-container>
            </div>
        </ng-template>

    </mat-menu>