//Angular Material Components
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// *************** FORM CONTROLS ***************
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core'; //FROM ANGULAR CORE
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// *************** NAVIGATION ***************
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

// *************** LAYOUT ***************
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';

// *************** BUTTONS & INDICATORS ***************
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';

// *************** POPUPS & MODALS ***************
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

// *************** DATA TABLE ***************

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { ButtonModule } from 'realsoft-reusable-components/button';
import { InputModule } from 'realsoft-reusable-components/input';
import { AlertModule } from 'realsoft-reusable-components/alert';
import { IconModule } from 'realsoft-reusable-components/icon';
import { TextAreaModule } from 'realsoft-reusable-components/textarea';
import { TabsModule } from 'realsoft-reusable-components/tabs';
import { ToggleButtonModule } from 'realsoft-reusable-components/toggle-btn';
import { TreeMenuModule } from 'realsoft-reusable-components/tree-menu';
import { RadioBtnModule } from 'realsoft-reusable-components/radio-btn';
import { SpinnerModule } from 'realsoft-reusable-components/spinner';
import { ProgressBarModule } from 'realsoft-reusable-components/progress-bar';
import { MenuModule } from 'realsoft-reusable-components/menu';
import { ExpansionPanelModule } from 'realsoft-reusable-components/expansion-panel';
import { CheckboxModule } from 'realsoft-reusable-components/checkbox';
import { ChipModule } from 'realsoft-reusable-components/chip';
import { SelectModule } from 'realsoft-reusable-components/select';
import { SelectionListModule } from 'realsoft-reusable-components/selection-list';
import { SliderModule } from 'realsoft-reusable-components/slider';
import { WizardModule } from 'realsoft-reusable-components/wizard';
import { RadioGroupModule } from 'realsoft-reusable-components/radio-group';
import { DatePickerInputModule } from 'realsoft-reusable-components/date-picker-input';
import { StepperModule } from 'realsoft-reusable-components/stepper';
import { ClipboardModule } from '@angular/cdk/clipboard';


const matDialogOptions: MatDialogConfig = {
  autoFocus: false,
  backdropClass: 'backdropBackground'
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    // CDK
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    // Material
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatSlideToggleModule,
    MatRippleModule,
    // UIKIT
    ButtonModule,
    InputModule,
    AlertModule,
    IconModule,
    TextAreaModule,
    TabsModule,
    ToggleButtonModule,
    TreeMenuModule,
    RadioBtnModule,
    SpinnerModule,
    ProgressBarModule,
    MenuModule,
    ExpansionPanelModule,
    CheckboxModule,
    ChipModule,
    SelectModule,
    SelectionListModule,
    SliderModule,
    WizardModule,
    RadioGroupModule,
    DatePickerInputModule,
    StepperModule,
    ClipboardModule
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: matDialogOptions
    }
  ]
})
export class MaterialModule { }
