import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  apiUrlPrefix = environment.apiUrlPrefix;
  authApiUrlPrefix = environment.authApiUrlPrefix;
  socketEndPoint = environment.apiUrlSocket;
  apiUrlMedia = environment.apiUrlMedia;

  constructor(private _http: HttpClient) { }

  private getFullUrl(url: string): string {
    let fullUrl: string = '';

    if (url.includes('users') || url.includes('auth') || (url.includes('payment') && !url.includes('paymentMethods')) ) {
      fullUrl = this.authApiUrlPrefix + url;
    } else if (url.includes('socket')) {
      fullUrl = this.socketEndPoint + url;
    } else if (url.includes('files')) {
      fullUrl = this.apiUrlMedia + url;
    }
    else {
      fullUrl = this.apiUrlPrefix + url;
    }

    return fullUrl;
  }

  post<T = any>(url: string, body: any): Observable<any> {
    const fullUrl: string = this.getFullUrl(url);
    return this._http.post<T>(fullUrl, body);
  }

  patch<T = any>(url: string, body: any): Observable<any> {
    const fullUrl: string = this.getFullUrl(url);
    return this._http.patch<T>(fullUrl, body);
  }

  get<T = any>(url: string, params?: HttpParams): Observable<any> {
    const fullUrl: string = this.getFullUrl(url);
    const opts = params ? { params: params } : {};
    return this._http.get<T>(fullUrl, opts);
  }

  put<T = any>(url: string, body: any): Observable<any> {
    const fullUrl: string = this.getFullUrl(url);
    return this._http.put<T>(fullUrl, body);
  }

  delete<T = any>(url: string): Observable<any> {
    const fullUrl: string = this.getFullUrl(url);
    return this._http.delete<T>(fullUrl);
  }

  deleteByBody<T = any>(url: string, body: any): Observable<any> {
    const fullUrl: string = this.getFullUrl(url);
    return this._http.delete<T>(fullUrl, { body });
  }

  postFileService<T = any>(url: string, data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': '' });
    return this._http.post<T>(url, data, { headers: headers });
  }
}
