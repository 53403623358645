import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { io, Socket } from 'socket.io-client';
import { Subject } from 'rxjs';
import { UserService, UserInfo } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  socket: Socket;
  private newRequestSubject = new Subject<any>();
  private newRequestFileSubject = new Subject<any>();

  private sendUserMessage = new Subject<any>();
  private sendGroupMessage = new Subject<any>();
  private createNewGroup = new Subject<any>();
  private removeUserFromGroup$ = new Subject<any>();
  private addYouToGroup$ = new Subject<any>();

  //notification
  private notificationSubject = new Subject<any>();
  private addNotification = new Subject<any>();

  // Deactivate Member
  private deactivateMember$ = new Subject<any>();

  userInfo: UserInfo | null;

  constructor(
    private _userService: UserService
  ) {}

  connect() {
    this.userInfo = this._userService.userInfo;

    if (!this.userInfo) {
      return;
    }

    let path = '';
    if (environment.production) {
      path = environment.SOCKET_PATH;
    } else {
      path = '';
    }

    this.socket = io(environment.SOCKET_ENDPOINT, {
      path: path,
      transports: ['websocket', 'polling'],
      autoConnect: true,
      query: { memberId: this.userInfo.memberId },
    });

    this.socket.on('fileUploadProgressNotification', data => {
      this.newRequestFileSubject.next(data);
    });

    this.socket.on('addMaterialRequest', data => {
      this.newRequestSubject.next(data);
    });

    this.socket.on('sendUserMessage', data => {
      this.sendUserMessage.next(data);
    });

    this.socket.on('sendGroupMessage', data => {
      this.sendGroupMessage.next(data);
    });

    this.socket.on('removeUserFromGroup', data => {
      this.removeUserFromGroup$.next(data);
    })

    this.socket.on('addMembers', data => {
      this.addYouToGroup$.next(data);
    })

    // Deactivate Member
    this.socket.on('logoutUser', data => {
      this.deactivateMember$.next(data);
    });

    //notification
    this.socket.on('sendNotificationToUser', data => {
      this.notificationSubject.next(data);
    });

    this.socket.on('addNotification', data => {
      this.addNotification.next(data);
    });

    this.socket.on('createNewGroup', data => {
      this.createNewGroup.next(data);
    });

    // Error handling
    this.socket.on('connect_error', error => {
      console.error('Socket connection error:', error);
    });

    this.socket.on('disconnect', reason => {
      console.log('Socket disconnected:', reason);
    });
  }

  disconnect() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }

  getNewRequests() {
    return this.newRequestSubject.asObservable();
  }

  getNewRequestFile() {
    return this.newRequestFileSubject.asObservable();
  }

  sendUserMessageChat() {
    return this.sendUserMessage.asObservable();
  }

  sendGroupMessageChat() {
    return this.sendGroupMessage.asObservable();
  }

  isLeaveGroup() {
    return this.removeUserFromGroup$.asObservable();
  }

  isNewGroup() {
    return this.addYouToGroup$.asObservable();
  }

  sendNotificationToUser() {
    return this.notificationSubject.asObservable();
  }

  sendUserMessageX(body: any) {
    this.socket.emit('sendUserMessage', body);
  }

  sendGroupMessageX(body: any) {
    this.socket.emit('sendGroupMessage', body);
  }

  startConversation(body: any) {
    this.socket.emit('startConversation', body);
  }

  getCreateNewGroup() {
    return this.createNewGroup.asObservable();
  }

  createNewGroupX(body: any) {
    this.socket.emit('createNewGroup', body);
  }

  removeUserFromGroup(body: any) {
    this.socket.emit('removeUserFromGroup', body);
  }

  editGroup(body: any) {
    this.socket.emit('editGroup', body);
  }

  //notification
  getNotification() {
    return this.notificationSubject.asObservable();
  }

  // Deactivate Member
  deactivateMember(memberId: number) {
    const body = { userId: memberId };

    this.socket.emit('logoutUser', body);
  }

  isMemberDeactivated() {
    return this.deactivateMember$.asObservable();
  }
}
