import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _router: Router,
    private _socketService: SocketService,
    private _userService: UserService
  ) {}


  /**
   * Log the user out and redirect to the login page and disconnect the socket connection and clear the session storage
   * @returns void
   */
  kickOut(): void {
    // Disconnect the socket
    this._socketService.disconnect();

    const lang = sessionStorage.getItem('EC-APP-LANG');

    // Clear session storage
    sessionStorage.clear();

    if (lang) {
      sessionStorage.setItem('EC-APP-LANG', lang);
    }

    // Clear the user info, permissions, districts, and selected district
    this._userService.clearUserInfo();

    // Redirect to the login page
    this._router.navigate(['auth/login']);
  }

  public getToken(): string | null {
    return sessionStorage.getItem('jwtToken');
  }
}
