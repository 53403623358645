import { Injectable } from '@angular/core';
import { PERMISSIONS } from '@core/enums/permissions.enum';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  // Define an observable to track the user's permissions.
  private userPermissionsSubject: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([]);
  public userPermissions$: Observable<string[]> =
    this.userPermissionsSubject.asObservable();

  constructor() {}

  setUserPermissions(storedPermissions: number[]) {
    if (storedPermissions) {
      const userPermissions = cloneDeep(storedPermissions);
      this.userPermissionsSubject.next(userPermissions.map(String));
    } else {
      this.userPermissionsSubject.next([]);
    }
  }

  // Check if the user has a specific permission.
  hasPermission(permission: string): boolean {
    let userPermissions: string[] = [];

    // Subscribe to the userPermissions$ observable to get the user's permissions.
    this.userPermissions$.subscribe((permissions) => {
      userPermissions = permissions;
    });

    // Check if the user has the required permission.
    return userPermissions.includes(permission);
  }
}
