import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface DataBar {
  name?: any;
  icon?: string;
}

@Component({
  selector: 'app-main-bar',
  templateUrl: './main-bar.component.html',
  styleUrls: ['./main-bar.component.scss'],
})
export class MainBarComponent {
  translationPrefix: string = `general.`;
  buttonName: string = '';

  configurations={type:'basic'}

  config = {
    type: 'button',
    style: 'primaryIcon',
    iconName: 'add',
    size: 'medium'
  }

  constructor() {}

  @Input() showButton: boolean = false; //enable add button
  @Input() data: DataBar;
  @Input() buttonText: string = 'addButton';
  @Output() addButton: EventEmitter<DataBar> = new EventEmitter<DataBar>();

  emitAddButton() {
    this.addButton.emit();
  }
}
