<div class="main-bar d-flex justify-content-between align-items-center">
  <div class="main-bar__title position-relative">
    <custom-icon [iconName]="'supervisor_account'" class="main-bar__title--icon"></custom-icon>
    <p class="mb-0 d-inline-block text-black">{{ data.name | translate }}</p>
  </div>

  <custom-button
    *ngIf="showButton"
    [configurations]="config"
    [buttonTxt]="translationPrefix + buttonText | translate"
    (click)="emitAddButton()"
  ></custom-button>
</div>
