import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(
    private _translateService: TranslateService,
    private notificationsService: NotificationsService
  ) {}

  showNgxToast(
    message: string,
    title: string,
    type: string,
    duration: number,
    config = {}
  ) {
    if (type === 'danger') {
      this.notificationsService.error(title, message, duration);
    }
    if (type === 'success') {
      this.notificationsService.success(title, message, duration);
    }
    if (type === 'info') {
      this.notificationsService.info(title, message, duration);
    }
    if (type === 'warning') {
      this.notificationsService.warn(title, message, duration);
    }
  }

  showTranslatedToast(
    status: string,
    msg: string,
    title: string,
    duration = 5000
  ) {
    let translated_title = '';
    let translated_msg = '';
    const sub = this._translateService
      .get(`general.backEndNotificationMessage.${title}` || ' ')
      .pipe(
        mergeMap(x => {
          translated_title = x;
          return this._translateService.get(msg || ' ');
        })
      )
      .subscribe((y: string) => {
        translated_msg = y;
        this.showNgxToast(translated_msg, translated_title, status, duration);
      });
    setTimeout(() => {
      sub.unsubscribe();
    }, 1000);
  }

  handleErrors(res: any, authService: AuthService) {
    const Title = 'BACKEND_NOTIFICATION_TITLE';
    const errors = res.error.errors;
    if (res.status === HttpStatusCode.Unauthorized) {
      authService.kickOut();
    } else if (errors) {
      Object.keys(errors).forEach(key => {
        if (typeof errors[key] === 'string')
          this.showTranslatedToast('danger', errors[key], Title, 10000);
        else
          errors[key].forEach((err: any) =>
            this.showTranslatedToast('danger', err, Title, 10000)
          );
      });
    } else {
      const Error = res?.error?.Errors[0]?.errorMsg?.info;
      const Msg = res?.error?.Errors[0]?.errorMsg?.message;
      if (Msg === 'Bad Request Exception') {
        if (typeof Error === 'string')
          this.showTranslatedToast('danger', Error, Title, 10000);
        if (Array.isArray(Error))
          this.showTranslatedToast('danger', Error[0], Title, 10000);
      } else {
        this.showTranslatedToast('danger', Msg, Title, 10000);
      }
    }
  }
}
