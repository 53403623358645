import { Injectable } from '@angular/core';
import { UserService } from './user.service';

type Permission = {
  id: number;
  permissionName: string;
};

type ScreenPermissions = {
  screenId: number;
  View?: Permission[];
  Edit?: Permission[];
};

type PermissionsObject = {
  [key: string]: ScreenPermissions;
};

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private _permissions: PermissionsObject = {
    STATISTICS_AND_ANALYTICS: {
      screenId: 1,
      View: [
        {
          id: 33,
          permissionName: 'VIEW_STATISTICS_AND_ANALYTICS',
        },
      ],
    },
    ELECTION_DAY: {
      screenId: 2,
      View: [
        {
          id: 34,
          permissionName: 'VIEW_ELECTION_DAY',
        },
      ],
      Edit: [
        {
          id: 68,
          permissionName: 'EDIT_ELECTION_DAY',
        },
      ],
    },
    CANDIDATE_INFORMATION: {
      screenId: 3,
      View: [
        {
          id: 36,
          permissionName: 'VIEW_CANDIDATE_INFORMATION',
        },
      ],
      Edit: [
        {
          id: 70,
          permissionName: 'EDIT_CANDIDATE_INFORMATION',
        },
      ],
    },
    MATERIALS_AND_SUPPLIES: {
      screenId: 4,
      View: [
        {
          id: 38,
          permissionName: 'VIEW_MATERIALS_AND_SUPPLIES',
        },
      ],
      Edit: [
        {
          id: 72,
          permissionName: 'EDIT_MATERIALS_AND_SUPPLIES',
        },
      ],
    },
    CAMPAIGN_DEFINITION: {
      screenId: 5,
      View: [
        {
          id: 44,
          permissionName: 'VIEW_CAMPAIGN_DEFINITION',
        },
      ],
      Edit: [
        {
          id: 77,
          permissionName: 'INSERT_DISTRICTS_PERMISSION',
        },
        {
          id: 78,
          permissionName: 'EDIT_CAMPAIGN_DEFINITION',
        },
      ],
    },
    ORDERS_ASSIGNED_TO_USER_COMMITTEE: {
      screenId: 6,
      View: [
        {
          id: 47,
          permissionName: 'VIEW_ORDERS_ASSIGNED_TO_USER_COMMITTEE',
        },
      ],
      Edit: [
        {
          id: 81,
          permissionName: 'EDIT_ORDERS_ASSIGNED_TO_USER_COMMITTEE',
        },
      ],
    },
    ORDERS_ASSIGNED_TO_USER: {
      screenId: 7,
      View: [
        {
          id: 48,
          permissionName: 'VIEW_ORDERS_ASSIGNED_TO_USER',
        },
      ],
      Edit: [
        {
          id: 82,
          permissionName: 'EDIT_ORDERS_ASSIGNED_TO_USER',
        },
      ],
    },
    ORDERS_IN_USER_DISTRICT: {
      screenId: 8,
      View: [
        {
          id: 49,
          permissionName: 'VIEW_ORDERS_IN_USER_DISTRICT',
        },
      ],
      Edit: [
        {
          id: 83,
          permissionName: 'EDIT_ORDERS_IN_USER_DISTRICT',
        },
      ],
    },
    TEAM_MEMBERS_IN_USER_DISTRICT: {
      screenId: 9,
      View: [
        {
          id: 50,
          permissionName: 'VIEW_TEAM_MEMBERS_IN_USER_DISTRICT',
        },
      ],
      Edit: [
        {
          id: 84,
          permissionName: 'EDIT_TEAM_MEMBERS_IN_USER_DISTRICT',
        },
      ],
    },
    TEAM_MEMBERS_IN_USER_COMMITTEE: {
      screenId: 10,
      View: [
        {
          id: 51,
          permissionName: 'VIEW_TEAM_MEMBERS_IN_USER_COMMITTEE',
        },
      ],
      Edit: [
        {
          id: 85,
          permissionName: 'EDIT_TEAM_MEMBERS_IN_USER_COMMITTEE',
        },
      ],
    },
    ACTIVITIES_AND_EVENTS_COORDINATED_BY_USER: {
      screenId: 11,
      View: [
        {
          id: 53,
          permissionName: 'VIEW_ACTIVITIES_AND_EVENTS_COORDINATED_BY_USER',
        },
      ],
      Edit: [
        {
          id: 87,
          permissionName: 'EDIT_ACTIVITIES_AND_EVENTS_COORDINATED_BY_USER',
        },
      ],
    },
    ACTIVITIES_AND_EVENTS_ASSIGNED_TO_USER_COMMITTEE: {
      screenId: 12,
      View: [
        {
          id: 54,
          permissionName:
            'VIEW_ACTIVITIES_AND_EVENTS_ASSIGNED_TO_USER_COMMITTEE',
        },
      ],
      Edit: [
        {
          id: 88,
          permissionName:
            'EDIT_ACTIVITIES_AND_EVENTS_ASSIGNED_TO_USER_COMMITTEE',
        },
      ],
    },
    ACTIVITIES_AND_EVENTS_IN_USER_DISTRICT: {
      screenId: 13,
      View: [
        {
          id: 55,
          permissionName: 'VIEW_ACTIVITIES_AND_EVENTS_IN_USER_DISTRICT',
        },
      ],
      Edit: [
        {
          id: 89,
          permissionName: 'EDIT_ACTIVITIES_AND_EVENTS_IN_USER_DISTRICT',
        },
      ],
    },
    OBJECTIONS_AND_NOTES_ASSIGNED_TO_USER_COMMITTEE: {
      screenId: 14,
      View: [
        {
          id: 59,
          permissionName:
            'VIEW_OBJECTIONS_AND_NOTES_ASSIGNED_TO_USER_COMMITTEE',
        },
      ],
      Edit: [
        {
          id: 93,
          permissionName:
            'EDIT_OBJECTIONS_AND_NOTES_ASSIGNED_TO_USER_COMMITTEE',
        },
      ],
    },
    OBJECTIONS_AND_NOTES_ASSIGNED_TO_USER: {
      screenId: 15,
      View: [
        {
          id: 60,
          permissionName: 'VIEW_OBJECTIONS_AND_NOTES_ASSIGNED_TO_USER',
        },
      ],
      Edit: [
        {
          id: 94,
          permissionName: 'EDIT_OBJECTIONS_AND_NOTES_ASSIGNED_TO_USER',
        },
      ],
    },
    OBJECTIONS_AND_NOTES_IN_USER_DISTRICT: {
      screenId: 16,
      View: [
        {
          id: 133,
          permissionName: 'VIEW_OBJECTIONS_AND_NOTES_IN_USER_DISTRICT',
        },
      ],
      Edit: [
        {
          id: 134,
          permissionName: 'EDIT_OBJECTIONS_AND_NOTES_IN_USER_DISTRICT',
        },
      ],
    },
    VOTERS_IN_USER_DISTRICT: {
      screenId: 17,
      View: [
        {
          id: 62,
          permissionName: 'VIEW_VOTERS_IN_USER_DISTRICT',
        },
      ],
      Edit: [
        {
          id: 96,
          permissionName: 'EDIT_VOTERS_IN_USER_DISTRICT',
        },
      ],
    },
    VOTERS_ASSIGNED_TO_USER: {
      screenId: 18,
      View: [
        {
          id: 63,
          permissionName: 'VIEW_VOTERS_ASSIGNED_TO_USER',
        },
      ],
      Edit: [
        {
          id: 97,
          permissionName: 'EDIT_VOTERS_ASSIGNED_TO_USER',
        },
      ],
    },
    COMMITTEES_USER_IS_PART_OF: {
      screenId: 19,
      View: [
        {
          id: 102,
          permissionName: 'VIEW_COMMITTEES_USER_IS_PART_OF',
        },
      ],
      Edit: [
        {
          id: 101,
          permissionName: 'EDIT_COMMITTEES_USER_IS_PART_OF',
        },
      ],
    },
    COMMITTEES_IN_USER_DISTRICT: {
      screenId: 20,
      View: [
        {
          id: 104,
          permissionName: 'VIEW_COMMITTEES_IN_USER_DISTRICT',
        },
      ],
      Edit: [
        {
          id: 103,
          permissionName: 'EDIT_COMMITTEES_IN_USER_DISTRICT',
        },
      ],
    },
    ELECTORAL_HEADQUARTERS_IN_USER_DISTRICT: {
      screenId: 21,
      View: [
        {
          id: 110,
          permissionName: 'VIEW_ELECTORAL_HEADQUARTERS_IN_USER_DISTRICT',
        },
      ],
      Edit: [
        {
          id: 109,
          permissionName: 'EDIT_ELECTORAL_HEADQUARTERS_IN_USER_DISTRICT',
        },
      ],
    },
    MESSAGING_SYSTEM: {
      screenId: 22,
      View: [
        {
          id: 117,
          permissionName:
            'ACTIVATE_MESSAGING_SYSTEM_AT_ELECTORAL_DISTRICT_LEVEL',
        },
        {
          id: 118,
          permissionName: 'ACTIVATE_MESSAGING_SYSTEM_AT_USER_COMMITTEE_LEVEL',
        },
        {
          id: 119,
          permissionName: 'ACTIVATE_MESSAGING_SYSTEM_AT_CAMPAIGN_LEVEL',
        },
      ],
    },
    FINANCIAL_MANAGER: {
      screenId: 23,
      View: [
        {
          id: 1,
          permissionName: 'ACTIVATE_FINANCIAL_MANAGER_PERMISSIONS',
        },
      ],
    },
    ACCOUNTANT: {
      screenId: 24,
      View: [
        {
          id: 2,
          permissionName: 'ACTIVATE_ACCOUNTANT_PERMISSIONS',
        },
      ],
    },
    VIEWER: {
      screenId: 25,
      View: [
        {
          id: 3,
          permissionName: 'ACTIVATE_VIEWER_PERMISSIONS',
        },
      ],
    },
    TRANSPORTATION_AND_TASKS: {
      screenId: 26,
      View: [
        {
          id: 139,
          permissionName: 'VIEW_TRANSPORTATION_AND_TASKS_ASSIGNED_TO_USER',
        },
        {
          id: 141,
          permissionName: 'VIEW_TRANSPORTATION_AND_TASKS_IN_USER_DISTRICT',
        },
      ],
      Edit: [
        {
          id: 140,
          permissionName: 'EDIT_TRANSPORTATION_AND_TASKS_ASSIGNED_TO_USER',
        },
        {
          id: 142,
          permissionName: 'EDIT_TRANSPORTATION_AND_TASKS_IN_USER_DISTRICT',
        },
      ],
    },
  };

  constructor(private userService: UserService) {}

  get permissions(): PermissionsObject {
    return this._permissions;
  }

  getViewIds(system: string): number[] {
    const ViewPermissions = this._permissions[system]?.View;

    if (Array.isArray(ViewPermissions)) {
      return ViewPermissions.map(permission => permission.id);
    } else {
      return [];
    }
  }

  getEditIds(system: string): number[] {
    const editPermissions = this._permissions[system]?.Edit;

    if (Array.isArray(editPermissions)) {
      return editPermissions.map(permission => permission.id);
    } else {
      return [];
    }
  }

  hasPermission(requiredPermission: string[]): boolean {
    let requiredPermissionsIds: number[] = [];

    for (let i = 0; i < requiredPermission.length; i++) {
      requiredPermissionsIds = requiredPermissionsIds.concat(this.getViewIds(requiredPermission[i]));
      requiredPermissionsIds = requiredPermissionsIds.concat(this.getEditIds(requiredPermission[i]));
    }

    if (requiredPermission) {
      // if one of the required permissions is granted, return true and allow access
      if (this.userService.userPermissions?.Data[0]?.permissions.some((permission) => requiredPermissionsIds.includes(permission))) {
        return true;
      }else{
        return false;
      }
    }
    // if no required permissions are set, allow access
    return true;
  }
}
