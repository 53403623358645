import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

export type UserInfo = {
  name: string;
  email: string;
  mobile: string | null;
  userName: string;
  profilePicture: string;
  memberId: number;
  userId: number;
  campaignId: number;
  userApps: number[];
  campaignCreator: boolean;
  isInitialized: number;
  isInReceptorCommittee: boolean;
  isFormRequestCommitteeAdmin: number;
  isMaterialRequestCommitteeAdmin: number;
  isDeliveryTasksCommitteeAdmin: number;
};

export type AuthResponse = {
  token: string;
  refreshToken: string;
  userInfo: UserInfo;
};

export type ApiDistrictResponse = {
  Errors: any[];
  StatusCode: number;
  Data: {
    districtsWithoutVirtual: District[];
    districtsWithVirtual: District[];
  };
  hasError: boolean;
};

export type District = {
  id: number;
  name: string;
  jordanDistrictId: number;
  campaignId?: number;
  electoralDistrictNatureId?: number;
};

export type PermissionsApiResponse = {
  Errors: any[];
  StatusCode: number;
  Data: CampaignMemberPermission[];
  hasError: boolean;
};

export type CampaignMemberPermission = {
  campaignMemberId: number;
  electoralDistrictId: number;
  permissions: number[];
};

export enum DistrictsFilterType {
  LocalsWithGD,
  GeneralsWithoutGD,
  GeneralsWithGD,
  NoChange,
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _userInfo: AuthResponse | null = null;
  private _userPermissions: PermissionsApiResponse | null = null;
  private _userDistrictsResponse: ApiDistrictResponse | null = null;
  private _userDistricts: District[] | null = null;
  public refreshDistrictList$ = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient, private router: Router) {}

  get userInfo(): UserInfo | null {
    return this._userInfo?.userInfo ?? null;
  }

  get userDistricts(): District[] {
    return this._userDistricts ?? [];
  }

  get userPermissions(): PermissionsApiResponse | null {
    return this._userPermissions;
  }

  get selectedDistrict(): District | null {
    // Get the selected district from the session storage
    const selectedDistrict = sessionStorage.getItem('selectedDistrict');
    if (selectedDistrict) {
      return JSON.parse(selectedDistrict);
    }
    return null;
  }

  private set _selectedDistrict(district: District | null) {
    // Save the selected district to the session storage
    sessionStorage.setItem('selectedDistrict', JSON.stringify(district));
  }

  /**
   * Get the current user
   * @returns Observable<AuthResponse | null>
   */
  getUser(): Observable<AuthResponse | null> {
    // If we already have the user, return it
    if (this._userInfo) {
      return of(this._userInfo);
    } else {
      // Otherwise, fetch the user from the API
      return this.http
        .get<AuthResponse>(environment.authApiUrlPrefix + 'users/auth/6')
        .pipe(
          tap(user => {
            this._userInfo = user ?? null;

            sessionStorage.setItem('jwtToken', user.token);
            sessionStorage.setItem('refreshToken', user.refreshToken);
          }),

          // If the user is not logged in, redirect to the login page
          catchError(err => {
            // if (err.status === HttpStatusCode.Unauthorized) {
            //   this.kickOut();
            // }
            return of(null);
          })
        );
    }
  }

  getUserDistricts(): Observable<ApiDistrictResponse> {
    if (this._userDistrictsResponse) {
      // set the selected district to the first district in the list if it is not already set
      if (!this.selectedDistrict) {
        this._selectedDistrict =
          this._userDistrictsResponse.Data.districtsWithoutVirtual[0];
      }
      return of(this._userDistrictsResponse);
    } else {
      return this.http
        .get<ApiDistrictResponse>(
          environment.apiUrlPrefix +
            'campaign/campaignMembers/getMemberDistricts'
        )
        .pipe(
          tap(districts => {
            // set the selected district to the first district in the list if it is not already set
            if (!this.selectedDistrict) {
              this._selectedDistrict =
                districts.Data.districtsWithoutVirtual[0];
            }
            this._userDistrictsResponse = districts;
          })
        );
    }
  }

  getUserInfoAndDistricts(): Observable<{
    user: AuthResponse | null;
    districts: ApiDistrictResponse | null;
  }> {
    // Call getUser and getUserDistricts
    const userObservable = this.getUser().pipe(catchError(() => of(null)));
    const districtsObservable = this.getUserDistricts().pipe(
      catchError(() => of(null))
    );

    // Use forkJoin to wait for both Observables to complete
    return forkJoin([userObservable, districtsObservable]).pipe(
      map(([user, districts]) => {
        // Combine or process the results as needed
        return {
          user,
          districts,
        };
      })
    );
  }

  setDistrictsFilterList(listDistrictsType: number) {
    const districts = this._userDistrictsResponse?.Data;
    // get any list of districts will view base on route
    // will be any of the following:
    // 1. User Local Districts with General District
    // 2. User General Districts with General District
    // 3. User General Districts without General District

    // get the general district if it's exist
    const generalDistrict =
      districts?.districtsWithVirtual.find(d => d.jordanDistrictId === 20) ??
      null;

    if (!generalDistrict && districts) {
      this._userDistricts = districts.districtsWithVirtual;
    } else if (districts) {
      switch (listDistrictsType) {
        case DistrictsFilterType.LocalsWithGD:
          this._userDistricts = districts.districtsWithVirtual;
          break;
        case DistrictsFilterType.GeneralsWithoutGD:
          this._userDistricts = districts.districtsWithoutVirtual;
          break;
        case DistrictsFilterType.GeneralsWithGD:
          if (districts?.districtsWithoutVirtual && generalDistrict)
            this._userDistricts = [
              ...districts.districtsWithoutVirtual,
              generalDistrict,
            ];
          break;
        case DistrictsFilterType.NoChange:
          // for the notAuthorized component do not change the districts list
          break;
        default:
          this._userDistricts = districts.districtsWithoutVirtual;
      }
    }

    // set the selected district if it's not set
    if (!this.selectedDistrict && this._userDistricts) {
      this._selectedDistrict = this._userDistricts[0];
    }

    // check if the selected district is in the list of the user districts
    const isDistrictExist = this._userDistricts?.find(
      d => d.id === this.selectedDistrict?.id
    );
    if (!isDistrictExist && this._userDistricts) {
      this._selectedDistrict = this._userDistricts[0];
    }

    this.refreshDistrictList$.next(true); // to update the filter in header component
  }

  getUserPermissions(): Observable<PermissionsApiResponse> {
    // check if the selected district changed
    const permissionDistrictId =
      this._userPermissions?.Data[0]?.electoralDistrictId;

    const isDistrictChanged: boolean =
      this.selectedDistrict?.id !== permissionDistrictId;

    if (this._userPermissions && !isDistrictChanged) {
      return of(this._userPermissions);
    } else {
      return this.http
        .get<PermissionsApiResponse>(
          environment.apiUrlPrefix +
            `campaign/resources/getPermissionIdsByDistrict/${this.selectedDistrict?.id}`
        )
        .pipe(
          tap(permissions => {
            this._userPermissions = permissions;
          })
        );
    }
  }

  /**
   * user in the header component when the user changes the district
   * @param district
   */
  onChangeSelectedDistrict(district: {
    id: number;
    en: string;
    jordanDistrictId: number;
  }): void {
    this._selectedDistrict = {
      id: district.id,
      name: district.en,
      jordanDistrictId: district.jordanDistrictId,
    };

    // to recall the guards to handle the new permissions
    this.reNavigate();
  }

  reNavigate() {
    // to ensure the navigation is treated as new

    // git the current route
    const currentRoute = this.router.url;
    const notAuthorizedRoute = '/campaign/not-authorized';
    this.router
      .navigateByUrl(notAuthorizedRoute, { skipLocationChange: true })
      .then(() => {
        this.router.navigateByUrl(currentRoute);
      });
  }

  /**
   * Clear the user districts response to force a refresh
   * of the user districts when the user add new district
   */
  clearUserDistrictsResponse(): void {
    this._userDistrictsResponse = null;
  }

  clearUserInfo(): void {
    // Clear the user info, permissions, and selected district
    this._userInfo = null;
    this._userPermissions = null;
    this._userDistrictsResponse = null;
    // Clear the selected district from the session storage, this is important for Initial Settings
    sessionStorage.removeItem('selectedDistrict');
  }
}
