import { Injectable } from '@angular/core';
import { RequestService } from '@core/services';
import { HttpParams } from '@angular/common/http';
import 'rxjs';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private authService: AuthService,
    private requestService: RequestService,
    private messageService: MessageService,
  ) {}

  post<T = any>(url: string, body?: any): Observable<T> {
    return this.requestService.post<T>(url, body).pipe(
      map((res: any) => res),
      catchError((res: any) => {
        this.messageService.handleErrors(res, this.authService);
        return throwError(res);
      })
    );
  }
  get<T = any>(url: string, params?: HttpParams): Observable<T> {
    const request = params
      ? this.requestService.get<T>(url, params)
      : this.requestService.get<T>(url);
    return request.pipe(
      map((res: any) => res),
      catchError((res: any) => {
        this.messageService.handleErrors(res, this.authService);
        return throwError(res);
      })
    );
  }
  put<T = any>(url: string, body?: any): Observable<T> {
    return this.requestService.put<T>(url, body).pipe(
      map((res: any) => res),
      catchError((res: any) => {
        this.messageService.handleErrors(res, this.authService);
        return throwError(res);
      })
    );
  }
  patch<T = any>(url: string, body?: any): Observable<T> {
    return this.requestService.patch<T>(url, body).pipe(
      map((res: any) => res),
      catchError((res: any) => {
        this.messageService.handleErrors(res, this.authService);
        return throwError(res);
      })
    );
  }
  delete<T = any>(url: string): Observable<T> {
    return this.requestService.delete<T>(url).pipe(
      map((res: any) => res),
      catchError((res: any) => {
        this.messageService.handleErrors(res, this.authService);
        return throwError(res);
      })
    );
  }
  deleteByBody<T = any>(url: string, body?: any) {
    return this.requestService.deleteByBody<T>(url, body).pipe(
      map((res: any) => res),
      catchError((res: any) => {
        this.messageService.handleErrors(res, this.authService);
        return throwError(res);
      })
    );
  }
  postFileService<T = any>(url: string, data: any) {
    return this.requestService.postFileService<T>(url, data).pipe(
      map((res: any) => res),
      catchError((res: any) => {
        this.messageService.handleErrors(res, this.authService);
        return throwError(res);
      })
    );
  }
}


