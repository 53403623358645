import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InitialSettingsGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.userService.getUserInfoAndDistricts().pipe(
      map(userData => {
        const user= userData?.user;
        const userDistricts = userData?.districts;

        if (user) {
          // If the user is a campaign creator and has not initialized their settings, rso can access the settings page
          if (user.userInfo?.campaignCreator && !user.userInfo.isInitialized) {
            return true;
          }

          // everything is fine, and he finished the initial settings, send him to the dashboard
          this.router.navigate(['campaign/dashboard']);
          return false;
        } else {
          // If there is no user object, kick them out to the login page
          this.authService.kickOut();
          return false;
        }
      })
    );
  }
}
