import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslationKeys } from '@core/enums/translation-keys.enum';
import { AuthService } from '@app/auth/auth.service';
import { Router } from '@angular/router';
import { GlobalService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  // private pendingHTTPRequests$ = new Subject<void>();
  isRefreshing: boolean = false;

  constructor(
    private _authService: AuthService,
    private router: Router,
    private globalService: GlobalService,
    private authService: AuthService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const userData = sessionStorage.AuthorizationData;
    const isUser = true;
    const helper = new JwtHelperService();
    const tempToken = sessionStorage.getItem('tempToken');
    const token = tempToken ? tempToken : sessionStorage.getItem('jwtToken');

    let isExpired = false;
    if (this.authService.isValidToken(token)) {
      isExpired = helper.isTokenExpired(token);
    } else {
      console.error('Invalid JWT token');
      sessionStorage.removeItem('tempToken');
      sessionStorage.removeItem('jwtToken');
    }

    const refreshToken = sessionStorage.getItem('refreshToken');

    //District filter
    // const url = this.router.url;
    // if (!url.includes('information')) this.globalService.ShowDistrictField.next(true)


    if (isUser) {
      if (token && isExpired && !this.isRefreshing) {
        this.isRefreshing = true;
        const body = {
          refreshToken: `${refreshToken}`,
        };

        this._authService.refreshToken(body).subscribe({
          next: (result: any) => {
            const accessToken = result.accessToken;
            sessionStorage.setItem('jwtToken', accessToken);
            this.isRefreshing = false;
            return location.reload();
          },
          error: (err: any) => {
            console.error('Error while refreshing token:', err);
            // this.notifications.error('Error', 'Session Expired');
            sessionStorage.removeItem('jwtToken');
            this.router.navigate(['auth/login']);
            this.isRefreshing = false;
            return location.reload();
          },
        });
      }

      if (!token) {
        const headers = new HttpHeaders({
          ['x-locale']:
            sessionStorage.getItem(TranslationKeys.TRANSLATION_KEY) ?? 'ar',
          ['app-id']: '5',
          'Cache-Control': 'no-cache, no-store, must-revalidate'
        });
        const req1 = req.clone({ headers });
        return next.handle(req1);
      }

      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
        ['x-locale']:
          sessionStorage.getItem(TranslationKeys.TRANSLATION_KEY) ?? 'ar',
        ['app-id']: '5',
        'Cache-Control': 'no-cache, no-store, must-revalidate'
      });

      if (
        req.url.includes(
          'https://realsoftapps.com/arcgis/rest/services/elect22_v1/election_AdministrativeLayer/MapServer'
        )
      ) {
        const clonedRequest = req.clone({
          headers: req.headers.delete('x-locale'),
        });

        return next.handle(clonedRequest);
      } else {
        const req1 = req.clone({ headers });

        return next.handle(req1);
        // .pipe(
        //   catchError((error: any) => {
        //     window.alert('DDD')
        //     if(isExpired) {
        //         this.pendingHTTPRequests$.next();
        //         const body = {
        //           refreshToken: `${refreshToken}`,
        //         };

        //         this._authService.refreshToken(body).subscribe({
        //           next: (result: any) => {
        //             const accessToken = result.accessToken;
        //             sessionStorage.setItem('jwtToken', accessToken);
        //             return location.reload();
        //           },
        //           error: (err: any) => {
        //             console.error('Error while refreshing token:', err);
        //             // this.notifications.error('Error', 'Session Expired');
        //             sessionStorage.removeItem('jwtToken');
        //             this.router.navigate(['auth/login']);
        //             return location.reload();
        //           },
        //         });
        //     }
        //      // Pass other errors through
        //     return throwError(error);
        //   })
        // )
      }
    } else {
      if (
        req.url.includes(
          'https://realsoftapps.com/arcgis/rest/services/elect22_v1/election_AdministrativeLayer/MapServer'
        )
      ) {
        const clonedRequest = req.clone({
          headers: req.headers.delete('x-locale'),
        });

        return next.handle(clonedRequest);
      } else {
        const headers = new HttpHeaders({
          ['x-locale']:
            sessionStorage.getItem(TranslationKeys.TRANSLATION_KEY) ?? 'ar',
          ['app-id']: '5',
          'Cache-Control': 'no-cache, no-store, must-revalidate'
        });
        const req1 = req.clone({ headers });

        return next.handle(req1);
      }
    }
  }
}
