<form [formGroup]="filterForm">
  <ng-container>
    <div
      class="table-header"
      *ngIf="isCustomColumn || isPageableTop || isGlobalSearch"
    >
      <!-- Filter -->
      <div class="global-search" *ngIf="displayedColumns.length">
          <ng-container *ngIf="isGlobalSearch">
            <custom-input
              [inputValue]="filterValue"
              [placeholder]="'general.search' | translate"
              [label]="'general.search' | translate"
              [configurations]="configurations"
              (secondIconClick)="secondIconClick()"
              [hidden]="this.filterValue ? false : true"
              (keyUp)="applyGlobalFilter($event)"
              (keydown.enter)="$event.preventDefault()"
            ></custom-input>
          </ng-container>
      </div>

      <div class="header-group">
        <div
          class="pagination-header"
          *ngIf="isPageableTop && tableDataSource.data.length > 1"
        >
          <!-- Pagination -->
          <mat-paginator
            [pageSizeOptions]="paginationSizes"
            [pageSize]="defaultPageSize"
            [length]="10000"
            (page)="onPaginationChange($event)"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
        <!-- filter icon -->
        <span
          title="{{ 'general.filter' | translate }}"
          class="material-icons position-relative filter-alt"
          *ngIf="isSidePanelFilter"
          [ngStyle]="{'color': sidePanelFilterColor}"
          (click)="opeCloseFilter()"
          >filter_alt</span
        >
        <!-- Custom Column Feature -->
        <ng-container *ngIf="isCustomColumn && !responsiveTable">
          <custom-button
            [configurations]="configButton"
            [buttonTxt]="translationPrefix + 'customColumns' | translate"
            (click)="openModal()"
          ></custom-button>
        </ng-container>
      </div>
    </div>

    <!-- Table -->
    <div
      class="table-container table__row--color"
      [class.overflow-visible]="
        !displayedColumns.length ||
        !tableDataSource.data.length ||
        !tableDataSource.filteredData.length
      "
    >
      <table
        mat-table
        [dataSource]="tableDataSource"
        matSort
        (matSortChange)="sortData($event)"
        [cdkDropListDisabled]="!dragEnabled"
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event)"
        class="w-100"
        *ngIf="displayedColumns.length"
      >
        <!-- action column -->
        <ng-container *ngFor="let row of rowActionIcon">
          <ng-container *ngIf="tableColumns?.length" [matColumnDef]="row">
            <th mat-header-cell *matHeaderCellDef class="text-center icon-column">
              {{ row }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [id]="row"
              (click)="emitRowAction(element, row)"
              class="text-center"
            >
              <ng-container *ngIf="![translateText('takeAction')].includes(row)">
                <button mat-button class="text-center">
                  <!-- [ngClass]="{'file-view': translateText('fileView')}" -->
                  <mat-icon
                    *ngIf="
                      ![
                        translateText('edit'),
                        translateText('fileView'),
                        translateText('copyIcon'),
                        translateText('viewIcon'),
                        translateText('deleteIcon'),
                        translateText('refreshIcon'),
                        translateText('personAdd'),
                        translateText('activation'),
                        translateText('locations'),
                        translateText('movement'),
                        translateText('takeAction')
                      ].includes(row)
                    "
                    >{{ row }}
                  </mat-icon>

                  <span
                    *ngIf="row === translateText('fileView')"
                    class="file-view"
                    >{{ "campaignMessages.fileView" | translate }}</span
                  >

                  <span
                    *ngIf="row === translateText('movement')"
                    class="material-icons main-icon"
                    >open_in_new</span
                  >

                  <span
                    title="{{ 'general.copyIcon' | translate }}"
                    *ngIf="row === translateText('copyIcon')"
                    class="material-icons main-icon"
                    >content_copy</span
                  >

                  <span
                    title="{{ 'general.details' | translate }}"
                    *ngIf="row === translateText('viewIcon')"
                    class="material-icons main-icon"
                    >info</span
                  >
                  <span
                    title="{{ 'general.refreshIcon' | translate }}"
                    *ngIf="row === translateText('refreshIcon')"
                    class="material-icons main-icon"
                    >refresh</span
                  >
                  <span
                    title="{{ 'general.locations' | translate }}"
                    *ngIf="row === translateText('locations')"
                    class="material-icons main-icon"
                    >location_on</span
                  >

                  <span
                    title="{{ 'general.edit' | translate }}"
                    *ngIf="row === translateText('edit')"
                    class="material-icons main-icon"
                    >edit</span
                  >

                  <!-- in general screens -->
                  <ng-container
                    *ngIf="!element.requestedBy && !element.formTypeId && !element.taskStatus"
                  >
                    <span
                      title="{{ 'general.delete' | translate }}"
                      class="material-icons red-color"
                      *ngIf="row === translateText('deleteIcon')"
                      >delete</span
                    >
                  </ng-container>
                  <!-- in assistance screen -->
                  <ng-container
                    *ngIf="
                      (element.requestedBy &&
                        element.requestStatusId === requestStatuses.NEW) ||
                      (element.taskStatus &&
                      (element.taskStatusId !== tasksStatusAdded.COMPLETED && element.taskStatusId !== tasksStatusAdded.UNDERWAY)) ||
                      (element.memberName &&
                        element.formStatusId &&
                        element.formStatusDescription === 'جديد') ||
                      element.formStatusDescription === 'New'
                    "
                  >
                    <span
                      title="{{ 'general.delete' | translate }}"
                      *ngIf="row === translateText('deleteIcon')"
                      class="material-icons red-color"
                      >delete</span
                    >
                  </ng-container>

                  <mat-icon *ngIf="row === translateText('personAdd')"
                    >person_add
                  </mat-icon>

                  <ng-container *ngIf="row === translateText('activation')">
                    <realsoft-toggle-button
                      (click)="$event.stopPropagation()"
                      (change)="onSelectionToggleChange($event, element)"
                      [checked]="selectionToggle.isSelected(element)"
                    ></realsoft-toggle-button>
                  </ng-container>
                </button>
              </ng-container>

              <ng-container
                *ngIf="row === translateText('takeAction') && !assignTask"
              >
                <span
                  [matMenuTriggerFor]="menu"
                  class="material-icons add-box pointer position-relative"
                  >add_box</span
                >
                <mat-menu #menu="matMenu">
                  <section class="action-container-custom">
                    <ng-container *ngFor="let menuItem of element.items">
                      <div
                        class="menu-button d-flex align-items-center p-0"
                        mat-menu-item
                        (click)="emitMenuItemAction(menuItem.id)"
                        [ngClass]="{
                          'text-primary-menu':
                            menuItem.id === 'assignedIndividual',
                          'text-success-menu': menuItem.id === 'sentDelivered',
                          'text-warning-menu':
                            menuItem.id === 'underConsideration',
                          'text-danger-menu': menuItem.id === 'reject'
                        }"
                      >
                        <!-- <mat-icon>dialpad</mat-icon> -->
                        <span
                          class="material-icons"
                          [ngClass]="{
                            'text-primary-menu':
                              menuItem.id === 'assignedIndividual',
                            'text-success-menu': menuItem.id === 'sentDelivered',
                            'text-warning-menu':
                              menuItem.id === 'underConsideration',
                            'text-danger-menu': menuItem.id === 'reject'
                          }"
                          [ngStyle]="
                            menuItem.icon
                              ? {
                                  'font-size': '18px'
                                }
                              : {
                                  'font-size': '12px'
                                }
                          "
                          >{{ menuItem.icon ?? "circle" }}</span
                        >
                        <span>{{ menuItem.label }}</span>
                      </div>
                    </ng-container>
                  </section>
                </mat-menu>
              </ng-container>

              <ng-container
                *ngIf="row === translateText('takeAction') && assignTask"
              >
                <span
                  *ngIf="showTakeAction(element.requestStatusId)"
                  [matMenuTriggerFor]="menu"
                  class="material-icons add-box pointer position-relative"
                  >add_box</span
                >
                <mat-menu #menu="matMenu">
                  <section class="action-container-custom">
                    <ng-container *ngFor="let menuItem of menuItems">
                      <div
                        class="menu-button d-flex align-items-center p-0"
                        mat-menu-item
                        (click)="emitMenuItemAction(menuItem.id)"
                      >
                        <!-- <mat-icon>dialpad</mat-icon> -->
                        <span
                          class="material-icons"
                          [ngClass]="menuItem.className"
                          [ngStyle]="{ color: menuItem.color }"
                          >{{ menuItem.icon ?? "circle" }}</span
                        >
                        <span [ngStyle]="{ color: menuItem.color }">{{
                          menuItem.label
                        }}</span>
                      </div>
                    </ng-container>
                  </section>
                </mat-menu>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <!-- checkbox action -->
        <ng-container *ngIf="isCheckbox">
          <ng-container *ngFor="let row of rowActionCheckbox">
            <ng-container [matColumnDef]="row">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                <div>
                  <realsoft-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                  ></realsoft-checkbox>
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [id]="row"
                (click)="emitRowAction(element, row)"
                class="text-center"
              >
                <realsoft-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="onSelectionChange($event, element)"
                  [checked]="selection.isSelected(element)"
                ></realsoft-checkbox>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>

        <!-- table Columns -->
        <ng-container
          *ngFor="let tableColumn of tableColumns"
          [matColumnDef]="tableColumn.name"
        >
          <!-- if sortable column header -->
          <ng-container
            *ngIf="enableSorting && tableColumn.isSortable; else notSortable"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              [mat-sort-header]="tableColumn.dataKey"
              [arrowPosition]="
                tableColumn.position === 'right' ? 'before' : 'after'
              "
              cdkDrag
            >
            <ng-container *ngIf="tableColumn.filterBefore && !tableColumn.justifyContent">
              <div class="d-flex align-items-center justify-content-center">
                <ng-container *ngIf="isFilterable && tableColumn.filter">
                  <table-filter (selectingOptionId)="selectOption($event)" (reloadFilter)="reload($event)" (filterSearch)="filterSearch($event)" class="realsoft-table-filter-before" [lang]="lang" [content]="{
                    type: tableColumn.filter.type,
                    labelEn: tableColumn.filter.labelEn,
                    labelAr: tableColumn.filter.labelAr,
                    control: filterForm.get(tableColumn?.filter?.formControl),
                    data: tableColumn?.filter?.options,
                    iconColor: tableColumn?.iconColor,
                    iconName: tableColumn?.iconName,
                    topValue : '-13px',
                    filterBefore: true
                    }"></table-filter>
                </ng-container>

                <span>{{ tableColumn.name }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="tableColumn.filterBefore && tableColumn.justifyContent">
              <div class="d-flex align-items-center">
                <ng-container *ngIf="isFilterable && tableColumn.filter">
                  <table-filter (selectingOptionId)="selectOption($event)" (reloadFilter)="reload($event)" (filterSearch)="filterSearch($event)" class="realsoft-table-filter-before" [lang]="lang" [content]="{
                    type: tableColumn.filter.type,
                    labelEn: tableColumn.filter.labelEn,
                    labelAr: tableColumn.filter.labelAr,
                    control: filterForm.get(tableColumn?.filter?.formControl),
                    data: tableColumn?.filter?.options,
                    iconColor: tableColumn?.iconColor,
                    iconName: tableColumn?.iconName,
                    topValue : '-13px',
                    filterBefore: true
                    }"></table-filter>
                </ng-container>

                <span>{{ tableColumn.name }}</span>
              </div>
            </ng-container>


            <ng-container *ngIf="!tableColumn.filterBefore">
              <span>{{ tableColumn.name }}</span>
              <ng-container *ngIf="isFilterable && tableColumn.filter">
                <table-filter (selectingOptionId)="selectOption($event)" (reloadFilter)="reload($event)" (filterSearch)="filterSearch($event)" class="realsoft-table-filter" [lang]="lang" [content]="{
                  type: tableColumn.filter.type,
                  labelEn: tableColumn.filter.labelEn,
                  labelAr: tableColumn.filter.labelAr,
                  control: filterForm.get(tableColumn?.filter?.formControl),
                  data: tableColumn?.filter?.options,
                  iconColor: tableColumn?.iconColor,
                  iconName: tableColumn?.iconName,
                  topValue : '-17px',
                  filterBefore: false
                  }"></table-filter>
              </ng-container>
          </ng-container>


              <!-- Search Input for every column -->
              <ng-container *ngIf="isSearchable">
                <mat-form-field
                  class="example-full-width isSearchable"
                  appearance="outline"
                  (click)="$event.stopPropagation()"
                >
                  <mat-label>{{ "general.search" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'general.search' | translate }}"
                    (keyup)="applyFilterColumn($event, tableColumn.dataKey)"
                  />
                </mat-form-field>
              </ng-container>

              <!-- Filter Icon -->
              <!-- <ng-container *ngIf="isFilterable && tableColumn.filter">
                <ng-container
                  *ngIf="tableColumn.filterIcon === 'search'; else default"
                >
                  <span
                    class="material-icons pointer filter-icon-custom position-absolute"
                    [ngClass]="{ 'custom-filter': enableSorting }"
                    [ngStyle]="{ color: tableColumn.iconColor }"
                    (click)="showFilter($event, tableColumn)"
                    >search</span
                  >
                </ng-container>
                <ng-template #default>
                  <ng-container>
                    <span
                      class="material-icons pointer filter-icon-custom position-absolute"
                      [ngClass]="{ 'custom-filter': enableSorting }"
                      [ngStyle]="{ color: tableColumn.iconColor }"
                      (click)="showFilter($event, tableColumn)"
                      >filter_alt</span
                    >
                  </ng-container>
                </ng-template>
                <div
                  *ngIf="tableColumn.filter.show"
                  class="filter-container"
                  appClickOutside
                  (clickOutside)="clickedOutside(tableColumn)"
                  (click)="$event.stopPropagation()"
                >
                  <ng-container *ngIf="tableColumn.filter?.type === 'string'">
                    <mat-form-field
                      class="text-field-no-icon-extra-small"
                      appearance="outline"
                    >
                      <input
                        matInput
                        placeholder="{{ 'general.search' | translate }}"
                        [value]="tableColumn.filter.value"
                        (keyup)="applyFilterColumn($event, tableColumn.dataKey)"
                      />
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="tableColumn.filter?.type === 'select'">
                    <mat-form-field
                      class="text-field-no-icon-extra-small"
                      appearance="outline"
                    >
                      <mat-select
                        [value]="tableColumn.filter.value"
                        (selectionChange)="applySelectFilter(tableColumn, $event)"
                        placeholder="{{ 'general.selectValue' | translate }}"
                      >
                        <mat-option
                          *ngFor="let option of tableColumn.filter?.options"
                          [value]="option.id"
                          (click)="selectOption(option)"
                        >
                          {{ option.en }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                </div>
              </ng-container> -->
            </th>
          </ng-container>

          <!-- else not sortable -->
          <ng-template #notSortable>

            <th
              mat-header-cell
              *matHeaderCellDef
              [class.text-right]="tableColumn.position === 'right'"
              cdkDrag
              [ngClass]="dragEnabled ? 'drag-pointer' : ''"
            >
            <ng-container *ngIf="tableColumn.filterBefore && !tableColumn.justifyContent">
              <div class="d-flex align-items-center justify-content-center">
                <ng-container *ngIf="isFilterable && tableColumn.filter">
                  <table-filter (selectingOptionId)="selectOption($event)" (reloadFilter)="reload($event)" (filterSearch)="filterSearch($event)" class="realsoft-table-filter-before" [lang]="lang" [content]="{
                    type: tableColumn.filter.type,
                    labelEn: tableColumn.filter.labelEn,
                    labelAr: tableColumn.filter.labelAr,
                    control: filterForm.get(tableColumn?.filter?.formControl),
                    data: tableColumn?.filter?.options,
                    iconColor: tableColumn?.iconColor,
                    iconName: tableColumn?.iconName,
                    topValue : '-13px',
                    filterBefore: true
                    }"></table-filter>
                </ng-container>

                <span>{{ tableColumn.name }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="tableColumn.filterBefore && tableColumn.justifyContent">
              <div class="d-flex align-items-center">
                <ng-container *ngIf="isFilterable && tableColumn.filter">
                  <table-filter (selectingOptionId)="selectOption($event)" (reloadFilter)="reload($event)" (filterSearch)="filterSearch($event)" class="realsoft-table-filter-before" [lang]="lang" [content]="{
                    type: tableColumn.filter.type,
                    labelEn: tableColumn.filter.labelEn,
                    labelAr: tableColumn.filter.labelAr,
                    control: filterForm.get(tableColumn?.filter?.formControl),
                    data: tableColumn?.filter?.options,
                    iconColor: tableColumn?.iconColor,
                    iconName: tableColumn?.iconName,
                    topValue : '-13px',
                    filterBefore: true
                    }"></table-filter>
                </ng-container>

                <span>{{ tableColumn.name }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="!tableColumn.filterBefore">
                <span>{{ tableColumn.name }}</span>
                <ng-container *ngIf="tableColumn.withIcon">
                  <span class="material-icons header-icon" [ngStyle]="{color: tableColumn.headerIconColor}" [matTooltip]="tableColumn.headerTooltip">{{tableColumn.headerIcon}}</span>
                </ng-container>
                <ng-container *ngIf="isFilterable && tableColumn.filter">
                  <table-filter (selectingOptionId)="selectOption($event)" (reloadFilter)="reload($event)" (filterSearch)="filterSearch($event)" class="realsoft-table-filter" [lang]="lang" [content]="{
                    type: tableColumn.filter.type,
                    labelEn: tableColumn.filter.labelEn,
                    labelAr: tableColumn.filter.labelAr,
                    control: filterForm.get(tableColumn?.filter?.formControl),
                    data: tableColumn?.filter?.options,
                    iconColor: tableColumn?.iconColor,
                    iconName: tableColumn?.iconName,
                    topValue : '-17px',
                    filterBefore: false
                    }"></table-filter>
                </ng-container>
            </ng-container>



              <!-- Search Input for every column -->



              <ng-container *ngIf="isSearchable">
                <mat-form-field
                  class="example-full-width isSearchable"
                  appearance="outline"
                >
                  <mat-label>{{ "general.search" | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="{{ 'general.search' | translate }}"
                    (keyup)="applyFilterColumn($event, tableColumn.dataKey)"
                  />
                </mat-form-field>
              </ng-container>





              <!-- Filter Icon -->
              <!-- <ng-container *ngIf="isFilterable && tableColumn.filter">
                <ng-container
                  *ngIf="tableColumn.filterIcon === 'search'; else default"
                >
                  <span
                    class="material-icons pointer filter-icon-custom position-absolute"
                    [ngClass]="{ 'custom-filter': enableSorting }"
                    [ngStyle]="{ color: tableColumn.iconColor }"
                    (click)="showFilter($event, tableColumn)"
                    >search</span
                  >
                </ng-container>
                <ng-template #default>
                  <ng-container>
                    <span
                      class="material-icons pointer filter-icon-custom position-absolute"
                      (click)="showFilter($event, tableColumn)"
                      [ngStyle]="{ color: tableColumn.iconColor }"
                      >filter_alt</span
                    >
                  </ng-container>
                </ng-template>

                <div
                  *ngIf="tableColumn.filter.show"
                  class="filter-container"
                  appClickOutside
                  (clickOutside)="clickedOutside(tableColumn)"
                >
                  <ng-container *ngIf="tableColumn.filter?.type === 'string'">
                    <mat-form-field
                      class="text-field-no-icon-extra-small"
                      appearance="outline"
                    >
                      <input
                        matInput
                        placeholder="{{ 'general.search' | translate }}"
                        [value]="searchValues[tableColumn.dataKey]"
                        (keyup)="applyFilterColumn($event, tableColumn.dataKey)"
                      />
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="tableColumn.filter?.type === 'select'">
                    <mat-form-field
                      class="text-field-no-icon-extra-small"
                      appearance="outline"
                    >
                      <mat-select
                        [(ngModel)]="tableColumn.filter.value"
                        (selectionChange)="applySelectFilter(tableColumn, $event)"
                        placeholder="{{ 'general.selectValue' | translate }}"
                      >
                        <mat-option
                          *ngFor="let option of tableColumn.filter?.options"
                          [value]="option.en"
                          (click)="selectOption(option)"
                        >
                          {{ option.en }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                </div>
              </ng-container> -->

              <ng-container *ngIf="tableColumn?.headerButton">
                <span class="add-category-margin">
                  <custom-button
                  (click)="add($event)"
                  [buttonTxt]="'campaignActivities.addCategory' | translate"
                  [configurations]="{
                    style: 'primaryStrokedIcon',
                    size: 'small',
                    iconName: 'add'
                  }"
                ></custom-button>
                </span>
              </ng-container>
            </th>
          </ng-template>

          <!-- column data -->
          <td
            mat-cell
            *matCellDef="let element"
            (click)="
              onMatCellClick(
                element,
                element[tableColumn.dataKey],
                tableColumn.dataKey
              )
            "
            [class.text-right]="tableColumn.position === 'right'"
            [style.width]="tableColumn.width ? tableColumn.width : 'auto'"
            
          >
            <!-- System Administrator Example-->
            <!-- <ng-container *ngIf="tableColumn.dataKey === 'name'">
          <ng-container *ngIf="element.campaignId; else nonCampaignName">
            <span>
              {{ element.firstName }} {{ element.secondName }} {{ element.thirdName }} {{ element.lastName }} <span [ngClass]="{ 'system-administrator': true }">مدير حملة</span>
            </span>
          </ng-container>
          <ng-template #nonCampaignName>
            {{ element.firstName }} {{ element.secondName }} {{ element.thirdName }} {{ element.lastName }}
          </ng-template>
        </ng-container> -->

            <!-- ToolTip Example -->
            <!-- <span
            [matTooltip]="
              tableColumn.dataKey === 'descriptionCommittee'? element['descriptionCommittee'] :
              tableColumn.dataKey === 'descriptionRole'? element['descriptionRole'] :
              null
            "
            matTooltipPosition="below"
            *ngIf="element['descriptionCommittee'] || element['descriptionRole']">
            {{ element | dataPropertyGetter : tableColumn.dataKey }}
          </span>
          <span *ngIf="!element['descriptionCommittee'] && !element['descriptionRole']">
            {{ element | dataPropertyGetter : tableColumn.dataKey }}
          </span> -->
            <!-- ToolTip Example -->
            <ng-container *ngIf="element?.classNames; else defaultStyle">
              <span [ngClass]="element?.classNames[tableColumn?.classNameKey]" (click)="dataClicked(element,
              element[tableColumn.dataKey],
              tableColumn.dataKey)">
                {{ element | dataPropertyGetter : tableColumn.dataKey | isNumber }}
              </span>
            </ng-container>

            <ng-template #defaultStyle>
              <ng-container>
                <span
                (click)="dataClicked(element,element[tableColumn.dataKey],tableColumn.dataKey)"
                  [ngClass]="{
                    clickable:
                      tableColumn.dataKey === 'members' ||
                      tableColumn.dataKey === 'completedVotersWork' ||
                      tableColumn.dataKey === 'assignedVotersWork' ||
                      tableColumn.dataKey === 'roles' ||
                      tableColumn.dataKey === 'candidatesNo' ||
                      tableColumn.dataKey === 'votersCount' ||
                      tableColumn.dataKey === 'memberCnt',
                    voterColor:
                      tableColumn.dataKey === 'statusId' ||
                      tableColumn.dataKey === 'formStatusId' ||
                      tableColumn.dataKey === 'voterStatus' ||
                      tableColumn.dataKey === 'isDataCompleted' ||
                      tableColumn.dataKey === 'isDataCompleted2016' ||
                      tableColumn.dataKey === 'isDataCompleted2020'
                  }"
                  [ngStyle]="
                    tableColumn.dataKey === 'statusId' ||
                    tableColumn.dataKey === 'formStatusId' ||
                    tableColumn.dataKey === 'voterStatus' ||
                    tableColumn.dataKey === 'isDataCompleted'
                      ? {
                          'background-color': element.voter_icon,
                          color: element.voter_text
                        }
                      : tableColumn.dataKey === 'electoralOrientationId' ||
                        tableColumn.dataKey === 'electoralPosition'
                      ? {
                          color: element.text_color
                        }
                      : null || tableColumn.dataKey === 'isDataCompleted2016'
                      ? {
                          'background-color': element.candidate_icon2016,
                          color: element.candidate_text2016
                        }
                      : null || tableColumn.dataKey === 'isDataCompleted2020'
                      ? {
                          'background-color': element.candidate_icon2020,
                          color: element.candidate_text2020
                        }
                      : null
                  "
                  matTooltip="{{ element | dataPropertyGetter : tableColumn.dataKey : true }}"
                  matTooltipClass="tooltip-style"
                >
                  {{ element | dataPropertyGetter : tableColumn.dataKey | isNumber }}
                </span>
                <ng-container *ngIf="element.systemAdmin">
                  <div class="additional-text-container">
                    <span class="additional-text-container__span">{{
                      "general.systemAdmin" | translate
                    }}</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="element.emailVerify">
                  <div class="additional-text-container">
                    <span class="additional-text-container__email-span">{{
                      "general.emailVerify" | translate
                    }}</span>
                  </div>
                </ng-container>
              </ng-container>
            </ng-template>

            <div
              class="pointer"
              *ngIf="
                tableColumn.dataKey === 'location' &&
                element.latitude !== '0' &&
                element.latitude !== '' &&
                element.latitude !== null
              "
            >
              <span class="material-icons position-relative location-icon"
                (click)="dataClicked(element, element[tableColumn.dataKey], tableColumn.dataKey)">
                location_on
              </span>
            </div>

            <div class="work-team-statuses">
              <span
              class="is-member-verified"
              *ngIf="tableColumn.dataKey === 'workMemberName' && !element.isVerified"
              (click)="
              onVerifiedCellClick(
                element,
                element[tableColumn.dataKey],
                tableColumn.dataKey
              )
            "
            >
              {{ "general.emailVerification" | translate }}
            </span>

            <span
              class="is-system-administrator"
              *ngIf="
                tableColumn.dataKey === 'workMemberName' && element.isCampaignCreator
              "
            >
              {{ "general.systemAdministrator" | translate }}
            </span>
            </div>



            <span *ngIf="tableColumn.dataKey === 'followers'">
              <!-- <multi-select
            [data]="element.canvassingMembers"
            [placeholder]="translationPrefix + 'followers' | translate"

            [configurations]="config"
            [chosenField]="'en'"
            (selectionChange)="onRoleSelectionChange($event)"
            [selectedItems]="selectedRoles"
          >
          </multi-select> -->

              <mat-form-field
                class="mat-form-field-type-mat-select single-select-form-field"
                appearance="outline"
              >
                <mat-select
                  placeholder=""
                  [(value)]="element.selectedFollowers"
                  (selectionChange)="onVoterSelectionChange($event)"
                  multiple
                >
                  <mat-option
                    *ngFor="let topping of campaignMembersList"
                    [value]="topping.id"
                    (click)="onRoleSelectionChange(topping.id)"
                    >{{ topping.en }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </span>
          </td>
        </ng-container>

        <!-- Header row filter search columns -->
        <ng-container matColumnDef="header-filter">
          <ng-container
            *ngFor="let tableColumn of tableColumns; let i = index"
            [matColumnDef]="'header-filter-' + i"
          >
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="50">
              <mat-form-field class="example-form-field">
                <input matInput placeholder="" />
              </mat-form-field>
            </th>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ 'selected-row': selection.isSelected(row) }"
          (click)="rowClick(row)"
        ></tr>
      </table>
      <div *ngIf="!displayedColumns.length" class="py-3 no-records">
        No Column Found
      </div>
      <div
        class="py-3 no-records"
        *ngIf="
          !tableDataSource.data.length || !tableDataSource.filteredData.length
        "
      >
        {{ "general.noRecord" | translate }}
      </div>
    </div>

    <!-- Pagination -->
    <mat-paginator
      *ngIf="isPageableBottom && displayedColumns.length && totalCount >= 5"
      [pageSizeOptions]="paginationSizes"
      [pageSize]="defaultPageSize"
      [pageIndex]="pageIndex"
      [length]="totalCount"
      (page)="onPaginationChange($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </ng-container>
</form>
