import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/services';
import { environment } from '../../../environments/environment';
import { CheckOTPResponse } from './pages/otp/otp.model';
import { ExpiredPlanResponse } from './pages/plans-payment/payment.model';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authApiUrlPrefix = environment.authApiUrlPrefix;

  constructor(
    private apiService: ApiService,
    private _http: HttpClient
  ) {
  }

  get isLoggedIn() {
    return !!sessionStorage.getItem('jwtToken')
  }

  signup(body: Object) {
    // const fullUrl: string = this.authApiUrlPrefix + 'users/register';
    return this.apiService.post('users/register', body);
  }

  checkOtp(body: Object) {
    return this.apiService.post<CheckOTPResponse | ExpiredPlanResponse>('users/checkOtp', body);
  }

  checkForgetPasswordOtp(body: Object) {
    return this.apiService.post('users/checkForgetPasswordOtp', body);
  }

  resendOTP(body: Object) {
    return this.apiService.post('users/resendOTP', body);
  }

  login(body: Object) {
    return this.apiService.post('users/login', body);
  }

  verifyCaptcha(body: Object) {
    return this.apiService.post('campaign/public/verify-captcha', body);
  }

  forgetPassword(body: Object) {
    return this.apiService.post('users/sendForgetPasswordValidationLink', body);
  }

  resetPassword(token: any, body: Object) {
    return this.apiService.post(`users/resetPassword?token=${token}`, body);
  }

  changePassword(body: Object) {
    return this.apiService.post(`users/changePassword`, body);
  }

  resetForgetPassword(token: any, body: Object) {
    return this.apiService.post(`users/forgetPassword?token=${token}`, body);
  }

  getGoogleAuthTokens(code: Object) {
    return this.apiService.get(`auth/getGoogleAuthTokens?code=${code}`);
  }

  viewCandidate(id: string | number) {
    return this.apiService.get(`campaign/candidates/${id}`);
  }


  refreshToken(body: Object) {
    return this.apiService.post('users/refresh', body);
  }

  getMemberRoles() {
    return this.apiService.get(`users/getMemberRoles`);
  }


  getRolePermissions(campaignRoleId: string | number) {
    return this.apiService.get(`users/getRolePermissions/${campaignRoleId}`);
  }

  encryptData(dataToEncrypt: string) {
    return CryptoJS.AES.encrypt(dataToEncrypt, environment.secretKey).toString();
  }

  decryptData(dataToDecrypt: string) {

    let data = CryptoJS.AES.decrypt(dataToDecrypt, environment.secretKey).toString(CryptoJS.enc.Utf8);
    return data;
  }

    /**
   * Checks if a token is valid.
   * @param token - The token to be checked.
   * @returns A boolean indicating whether the token is valid or not.
   */
    isValidToken(token: string | null): boolean {
      if (token) {
        const parts = token.split('.');
        return parts.length === 3;
      } else if(token === null) {
        return true;
      }
      return false;
    }

  //   refreshToken(body: Object) {
  //   return this._http.post('http://localhost:3000/users/refresh', body);
  // }

}
