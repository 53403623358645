import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

/**
 * A custom pipe that checks if a value is a number and formats it using the DecimalPipe.
 */
@Pipe({
  name: 'isNumber',
})
export class IsNumberPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  /**
   * Transforms the input value into a formatted number using the DecimalPipe.
   * Example: 12345.6789 => 12,345.6789
   * If the input value is not a number, it returns the original value.
   * @param value - The value to be transformed.
   * @returns The transformed value.
   */
  transform(value: any): any {
    if (typeof value === 'string') {
      return value;
    }

    // Define the regular expression pattern for Jordan mobile numbers
    const jordanMobilePattern = /^(077|078|079)\d{7}$/;

    return isNaN(value) || jordanMobilePattern.test(value)
      ? value
      : this.decimalPipe.transform(value, '1.0-4');
  }
}
