import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MaterialModule } from './material.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NumbersOnlyDirective, RtlDirective } from './directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { DataPropertyGetterPipe } from './components/custom-table/pipe/data-property-getter.pipe';
import { ClickOutsideDirective } from './components/custom-table/directive/clickOutside.directive';
import { ClickStopPropagationDirective } from './components/custom-table/directive/clickStopPropagation';
import { CustomColumnModalComponent } from './components/custom-table/custom-column-modal/custom-column-modal.component';
// import { ReusableComponentsModule } from 'realsoft-reusable-components';
import { MainBarComponent } from './components/main-bar/main-bar.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './components/custom-table/service/custom-mat-paginator';
import  {  NgxEmojiPickerModule  }  from  'ngx-emoji-picker';
import { TableFiltersComponent } from './components/custom-table/table-filters/table-filters.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PermissionContainerComponent } from '@app/main/pages/permission-container/permission-container.component';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [
    RtlDirective,
    NumbersOnlyDirective,
    CustomTableComponent,
    TableFiltersComponent,
    MainBarComponent,
    DataPropertyGetterPipe,
    CustomColumnModalComponent,
    ConfirmationModalComponent,
    ClickOutsideDirective,
    ClickStopPropagationDirective,
    PermissionContainerComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEmojiPickerModule,
    NgxSkeletonLoaderModule,
    PipesModule,
  ],
  exports: [
    MaterialModule,
    RouterModule,
    TranslateModule,
    RtlDirective,
    NumbersOnlyDirective,
    FormsModule,
    ReactiveFormsModule,
    CustomTableComponent,
    MainBarComponent,
    CustomColumnModalComponent,
    ConfirmationModalComponent,
    NgxEmojiPickerModule,
    ClickOutsideDirective,
    PermissionContainerComponent,
    NgxSkeletonLoaderModule,
  ],
  providers: [{
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl
  },
  DecimalPipe,
]
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
