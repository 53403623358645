import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './@core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslationService } from '@core/services/translate.service';
import { windowFactory } from '@core/token/windowFactory';
import { navigatorFactory } from '@core/token/navigatorFactory';
import { LoaderService } from '@core/loader/loader.service';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
// import { ReusableComponentsModule } from 'realsoft-reusable-components';
import { TitleService } from '@core/services/title.service';
import { StepperModule } from 'realsoft-reusable-components/stepper';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { GoogleMapsLoaderService } from '@core/services/googlemapsLoader.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    // ReusableComponentsModule,
    CoreModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StepperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    ToastrModule.forRoot({
      enableHtml: true,
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning',
      },
    }),
    NgxEchartsModule.forRoot({echarts}),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    // ReusableComponentsModule
  ],
  bootstrap: [AppComponent],
  providers: [
    LoaderService,
    TranslationService,
    GoogleMapsLoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: 'window', useFactory: windowFactory },
    { provide: 'navigator', useFactory: navigatorFactory },
    { provide: LOCALE_ID, useValue: 'en' } // Default language (e.g., English)
  ],
})
export class AppModule {
  constructor(private titleService: TitleService) {
    this.titleService.setTitle();
  }
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
