import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate {
  constructor(private authService: AuthService, private userService: UserService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.userService.getUserInfoAndDistricts().pipe(
      map(userData => {
        const user= userData?.user;
        const userDistricts = userData?.districts;

        if (user) {
          // If the user is a campaign creator and has not initialized their settings, redirect them to the settings page
          if (user?.userInfo?.campaignCreator && !user?.userInfo?.isInitialized) {
            this.router.navigate(['initial/settings']);
            return false;
          }

          // everything is fine, return true
          return true;
        } else {
          // If there is no user object, kick them out to the login page
          this.authService.kickOut();
          return false;
        }
      })
    );
  }
}
