import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableColumn } from '../interface/TableColumn';

export interface ModalData {
  title?: string;
  message?: string;
  showColumn?: Function;
  tableColumns: TableColumn[];
  tableData?: any;
}

const COLUMN_KEYS = {
  CANDIDATE: 'list',
  LISTS: 'listSymbol',
  INFLUENCER: ['influenceType', 'canvassingMembersOutput'],
  TEAMWORK: 'availableTimes',
  VOTER: 'voterStatus',
  SUPPLIES: 'supplyDate',
  WORK: 'days',
  OFFICE: 'campaignOfficeResponsible',
  ALL_CANDIDATES: 'allocationOfSeatsName',
  VEHICLES: 'vehicleType',
  TASK_NAME: 'taskName',
  SUPPORT: 'requestName',
  ASSIGNED_SUPPORT: 'remainingQuantity',
  MY_NOTES: 'complaintName',
  HOUSE: 'votersInHouseCount'
};

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-column-modal.component.html',
  styleUrls: ['./custom-column-modal.component.scss'],
})
export class CustomColumnModalComponent {
  translationPrefix: string = `general.`;

  tableColumns: TableColumn[];
  originalTableColumns: TableColumn[];
  tableData: any;
  disabledBtn: boolean = false;
  isCandidate: boolean = false;
  isLists: boolean = false;
  isSupplies: boolean = false;
  isInfluencer: boolean = false;
  isTeamwork: boolean = false;
  isVoter: boolean = false;
  isWork: boolean = false;
  isOffice: boolean = false;
  isAllCandidates: boolean = false;
  isVehicles: boolean = false;
  isTaskName: boolean = false;
  isSupport: boolean = false;
  isAssignedSupport: boolean = false;
  isMyNotes: boolean = false;
  isHouse: boolean = false;
  disabledText: string = '';

  configSave: Object = {
    type: 'button',
    style: 'primary',
    size: 'large',
    disabled: this.disabledBtn,
  };

  configClose: Object = {
    type: 'button',
    style: 'primaryStroked',
    size: 'large',
  };

  constructor(
    public dialogRef: MatDialogRef<CustomColumnModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {
    this.originalTableColumns = JSON.parse(JSON.stringify(data.tableColumns)); // make a deep copy of the original data
    this.tableColumns = [...this.originalTableColumns];
    this.tableData = [data.tableData[0] ?? {}];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectItem() {
    this.dialogRef.close(this.tableColumns);
  }


hasColumn(tableColumns: any, keys: any) {
  if (Array.isArray(keys)) {
    return tableColumns.some((item:any) => keys.includes(item.dataKey));
  }
  return tableColumns.some((item:any) => item.dataKey === keys);
}


onColumnChange() {
  this.tableColumns = [...this.originalTableColumns];
  
  const filterCol = this.tableColumns.filter(col => col.show);
  
  this.isCandidate = this.hasColumn(this.tableColumns, COLUMN_KEYS.CANDIDATE);
  this.isLists = this.hasColumn(this.tableColumns, COLUMN_KEYS.LISTS);
  this.isInfluencer = this.hasColumn(this.tableColumns, COLUMN_KEYS.INFLUENCER);
  this.isTeamwork = this.hasColumn(this.tableColumns, COLUMN_KEYS.TEAMWORK);
  this.isVoter = this.hasColumn(this.tableColumns, COLUMN_KEYS.VOTER);
  this.isSupplies = this.hasColumn(this.tableColumns, COLUMN_KEYS.SUPPLIES);
  this.isWork = this.hasColumn(this.tableColumns, COLUMN_KEYS.WORK);
  this.isOffice = this.hasColumn(this.tableColumns, COLUMN_KEYS.OFFICE);
  this.isAllCandidates = this.hasColumn(this.tableColumns, COLUMN_KEYS.ALL_CANDIDATES);
  this.isVehicles = this.hasColumn(this.tableColumns, COLUMN_KEYS.VEHICLES);
  this.isTaskName = this.hasColumn(this.tableColumns, COLUMN_KEYS.TASK_NAME);
  this.isSupport = this.hasColumn(this.tableColumns, COLUMN_KEYS.SUPPORT) && !this.hasColumn(this.tableColumns, COLUMN_KEYS.ASSIGNED_SUPPORT);
  this.isAssignedSupport = this.hasColumn(this.tableColumns, COLUMN_KEYS.ASSIGNED_SUPPORT);
  this.isMyNotes = this.hasColumn(this.tableColumns, COLUMN_KEYS.MY_NOTES);
  this.isHouse = this.hasColumn(this.tableColumns, COLUMN_KEYS.HOUSE);
  
  const conditionSets = [
    { conditions: [this.isLists, this.isInfluencer, this.isTeamwork, this.isVoter, this.isSupplies, this.isWork], min: 2, max: 5 },
    { conditions: [this.isOffice, this.isCandidate, this.isAllCandidates, this.isVehicles, this.isSupport, this.isHouse], min: 2, max: 6 },
    { conditions: [this.isTaskName, this.isAssignedSupport, this.isMyNotes], min: 2, max: 9 },
    // { conditions: [this.isAssignedSupport], min: 2, max: 8 }
  ];

  this.disabledBtn = conditionSets.some(({ conditions, min, max }) => 
    conditions.some(Boolean) && (filterCol.length > max || filterCol.length < min)
  );
}

  onSortedColumn(event: TableColumn[]) {
    this.tableColumns = event;
  }
}
