export enum requestStatuses {
  ASSIGN_TO_COMMITTEE = 1,
  ASSIGN_TO_MEMBER = 2,
  HANDOVER = 3,
  UNDER_STUDY = 4,
  REJECT = 5,
  NEW = 6,
  ASSIGN_TO_DRIVER = 7,
}

export enum noteStatuses {
  ASSIGN_TO_COMMITTEE = 1,
  ASSIGN_TO_MEMBER = 2,
  HANDOVER = 3,
  UNDER_STUDY = 4,
  REJECT = 5,
  NEW = 6,
}

export enum tasksStatusAdded {
  UNCOMPLETED = 1,
  COMPLETED = 2,
  UNDERWAY = 4,
}