export const environment = {
  production: true,
  IS_PAYMENT_ENABLED: false,
  apiUrlPrefix:    'https://elections.afaq-me.com/electionapi/api/',
  authApiUrlPrefix:    'https://elections.afaq-me.com/authenticationapi/',
  SOCKET_ENDPOINT: '/campaignweb',
  SOCKET_PATH: '/socketapi/socket.io',
  SETTING_PATH: '/',
  apiUrlSocket:    'https://elections.afaq-me.com/socketapi/api/',
  apiUrlMedia: 'https://elections.afaq-me.com/mediaapi/',
  REDIRECT_URI:    'https://elections.afaq-me.com/auth/auth',
  IP_INFO_TOKEN: 'cef6f7238076e7',
  IP_INFO_URL: 'https://ipinfo.io',
  VERSION_NUMBER: '1.4.0',
  googleMapsApiKey: 'AIzaSyDGh7pZ3MIFp39RUsax2Ba-eGalyy6-IAw',
  secretKey: "AulJx4e0xirfvM3vpr4MxhswwgQodEIA",
  efawateercom: {
    link: "https://customer.efawateercom.jo/login",
    directPay: "http://sawtakpayment.com/SawtakDirectPaymnt/EfawateercomDirectPay"
  },
  sawtakFin: {
    lookupServiceURL: 'https://elections.afaq-me.com/electionapi/api/campaign/public/getFinancialLookups',
    usersServiceURL: `https://elections.afaq-me.com/authenticationapi/users/validate`,
  },
  FACEBOOK_LOGIN_URL: 'https://elections.afaq-me.com/authenticationapi/auth/facebook',
  GOOGLE_LOGIN_URL: 'https://elections.afaq-me.com/authenticationapi/auth/google',
  IS_MOBILE_PAGE: true,
  baseSocialUrl: 'https://elections.afaq-me.com/Election_Campaign_Testing/campaign/activities/',
};
