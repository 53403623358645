import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TitleService {

  productName:string;
  private currentTitleSubject = new BehaviorSubject<string>('');
  private currentLangSubject = new BehaviorSubject<string>('en');

  get currentTitle() {
    return this.currentTitleSubject.asObservable();
  }

  constructor(
    private title: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  setTitle() {
    this.translate.onLangChange.subscribe(() => {
      // Update the translated title whenever the language changes
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) route = route.firstChild;
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data),
          map((data) => data['title']),
          mergeMap((title) => this.translate.stream(title)), // Use the translate stream to get the translated title
        )
        .subscribe((title: string) => {
          this.translate.currentLang == 'ar' ? this.productName = 'آفاق' :  this.productName = 'AFAQ';
          this.currentTitleSubject.next(title);
          this.title.setTitle(`${this.productName} - ${title}`);
        });
    });
  }
}
