import {
  Component,
  EventEmitter,
  Input,
  AfterViewInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { EventColumn, FilterParam, TableColumn } from './interface/TableColumn';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { CustomColumnModalComponent } from './custom-column-modal/custom-column-modal.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { debounce, interval } from 'rxjs';
import { TranslationService } from '@core/services/translate.service';

import { requestStatuses, tasksStatusAdded } from '@core/enums/support-request-statuses.enum';
import { FormService } from './service/form.service';


@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent  implements OnChanges {
  translationPrefix: string = `general.`;
  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];
  filterValues: any = {};
  filterValue: any;
  // searchForm: FormGroup = new FormGroup({});
  requestStatuses = requestStatuses;
  tasksStatusAdded = tasksStatusAdded;
  filterForm = new FormGroup({})


  configurations = {
    fieldType: 'multiIconsIncluded',
    firstIcon: 'search',
    secondIcon: 'clear',
    size: 'small',
  };

  selection = new SelectionModel<any>(true, []);
  selectionToggle = new SelectionModel<any>(true, []);
  globalSearch = new FormControl(null);

  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;

  @Input() isGlobalSearch: boolean = false; // enable global search
  @Input() isPageableTop: boolean = false; // enable pagination top
  @Input() isPageableBottom: boolean = false; // enable pagination bottom
  @Input() paginationSizes: number[] = [5, 10, 15]; // pagination size
  @Input() defaultPageSize = this.paginationSizes[1]; // default page size
  @Input() enableSorting: boolean = true; // enable sorting
  @Input() numericColumns: string[]; // numerical columns sorted
  @Input() isFilterable: boolean = false; // can a column be filtered?
  @Input() isSearchable: boolean = false; //can a column be searched?
  @Input() isCheckbox: boolean = false; //can a column be checkbox?
  @Input() isCustomColumn: boolean = false; // show or hide custom column
  @Input() isSidePanelFilter: boolean = false; // show or hide Side menu filter
  @Input() tableColumns: TableColumn[]; // data table columns
  @Input() dragEnabled = false; // enable drag and drop
  @Input() rowActionIcon: string[];
  @Input() statusesToHideTakeActionIcon: number[] = []; // Array of status ids for which the take action icon should be hidden
  @Input() rowActionCheckbox: string[];
  @Input() filterParams: FilterParam[] =[];
  @Input() responsiveTable: boolean = false;
  @Input() sidePanelFilterColor: string  = '#000';
  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }
  @Input() assignTask=false;
  @Input() lang ='ar';

  @Input() menuItems: any[]; // data table columns

  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() rowAction: EventEmitter<EventColumn> =
    new EventEmitter<EventColumn>();
  @Output() menuItemAction: EventEmitter<EventColumn> =
    new EventEmitter<EventColumn>();
  @Output() columnsChange: EventEmitter<TableColumn[]> = new EventEmitter<
    TableColumn[]
  >();
  @Output() sortedColumn: EventEmitter<TableColumn[]> = new EventEmitter<
    TableColumn[]
  >();
  @Output() filteredData: EventEmitter<any> = new EventEmitter<any>();
  @Output() totalRecords = new EventEmitter<number>();
  @Output() searchFilteredData: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClickEvent = new EventEmitter();
  @Output() sqCellClick = new EventEmitter();
  @Output() linkClicked = new EventEmitter();
  @Output() isVerifiedClick = new EventEmitter();
  @Output() checkboxChange = new EventEmitter<any>();
  @Output() selectionChange: EventEmitter<any[]> = new EventEmitter();
  @Output() selectionToggleChange: EventEmitter<any[]> = new EventEmitter();
  @Output() filteredSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() isSidePanelFilterClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sortParameters: EventEmitter<any> = new EventEmitter<any>();

  @Output() paginationChange: EventEmitter<PageEvent> =
    new EventEmitter<PageEvent>();
  @Output() selectingRolesChange = new EventEmitter<any>();
  @Output() selectingVoterChange = new EventEmitter<any>();
  @Input() campaignMembersList: any;
  @Input() selectedItems: any;
  @Input() paginatedSorting: boolean =false

  @Input() tableFilteredData: any[] = [];

  @Input() totalCount: number = 50;
  @Input() pageIndex: number = 0;
  @Input() paginated: boolean = true;
  reloadFilter =false;

  @Output() selectingOptionId = new EventEmitter<any>();
  @Output() columnSearch = new EventEmitter<any>();
  @Output() clearSearch = new EventEmitter<any>();
  @Output() headerButton = new EventEmitter<any>();
  @Input() searchValues: Record<string, string | number>;

  configButton = {
    type: 'button',
    style: 'strokedIcon',
    iconName: 'settings',
    size: 'small',
  };

  configButtonTwo = {
    type: 'button',
    style: 'primaryIcon',
    iconName: 'cloud_upload',
    size: 'small',
  };

  config = {
    isMultiple: true,
  };

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private formService: FormService,
    private translate: TranslateService,
    public translationService: TranslationService
  ) {
  }

  emitMenuItemAction(actionName: any) {
    this.menuItemAction.emit(actionName);
  }

  secondIconClick() {
    this.filterValue =''
    this.tableDataSource.filter = '';
    this.filteredSearch.emit('');
  }

  reload(e:any){
    if(e){
      this.clearSearch.emit(e)
    }
  }

  add(e:any){
    this.headerButton.emit(e)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rowActionCheckbox = [' '];
    this.showColumn();
    this.intiTableFilters();
    this.filterForm.valueChanges
    .subscribe((value) => {
      this.formValueChanged.emit(value);
      this.totalRecords.emit(this.tableDataSource.filteredData.length);
    });

    if(this.reloadFilter){
      this.filterForm.updateValueAndValidity();
    }

    // this.searchForm
    // .get('customInputControl')?.valueChanges.pipe(debounceTime(500))
    // .subscribe((newValue: any) => {
    //   // newValue contains the debounced input value
    //   this.applyGlobalFilter(newValue);
    // });
  }


  sortData(sortParameters: Sort) {
    if (this.enableSorting && !this.paginatedSorting) {
      const keyName = sortParameters.active;
      let data = this.tableDataSource.data.slice(); // make a copy of the data array

      const numericColumns = this.numericColumns // define the columns that should be sorted numerically

      if (sortParameters.direction === 'asc') {
        data.sort((a: any, b: any) => {
          if (a[keyName] === undefined || b[keyName] === undefined) {
            return 0; // fallback to no sort if keyName is undefined
          } else if (numericColumns.includes(keyName)) {
            if(a[keyName].includes('%')){
              const first= a[keyName].replace('%', ' ')
              const second= b[keyName].replace('%',' ')
              return first - second
            }
            else return a[keyName] - b[keyName];
          } else {
            return a[keyName]?.localeCompare(b[keyName]);
          }
        });
      } else if (sortParameters.direction === 'desc') {
        data.sort((a: any, b: any) => {
          if (a[keyName] === undefined || b[keyName] === undefined) {
            return 0; // fallback to no sort if keyName is undefined
          } else if (numericColumns.includes(keyName)) {
            if(a[keyName].includes('%')){
              const first= a[keyName].replace('%', ' ')
              const second= b[keyName].replace('%',' ')
              return second - first
            }
            else return b[keyName] - a[keyName];
          } else {
            return b[keyName]?.localeCompare(a[keyName]);
          }
        });
      } else {
        data = this.tableDataSource.data.slice(); // reset to the original data array
      }

      this.tableDataSource.data = data; // update the data source with the sorted data
    }


    this.sortParameters.emit(sortParameters)
  }



  // applyDynamicFilter(event: Event) {
  //   this.searchForm
  //     .get('customInputControl')
  //     ?.valueChanges?.pipe(debounce(() => interval(500)))
  //     .subscribe((filterValue: any) => {
  //       this.filteredData.emit(filterValue);
  //       this.filteredSearch.emit(filterValue);
  //     });
  // }

  applyGlobalFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = value;
    // this.tableDataSource.filter = filterValue.trim().toLocaleLowerCase();

    // Show a message when there is no data found
    this.tableDataSource.filteredData = this.tableDataSource.filteredData || [];

    // this.filteredData.emit(this.tableDataSource.filteredData);
    this.filteredSearch.emit(this.filterValue);

    if (this.tableDataSource.paginator) {
      this.tableDataSource.paginator.firstPage();
    }
  }

  applyFilterColumn(event: Event, column: string) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.tableDataSource.filterPredicate = (data: any, filter: string) => {
      const columnValue = data[column].toString().toLowerCase();
      return columnValue.indexOf(filter) !== -1;
    };
    // make filter on the display data without call API
    this.tableDataSource.filter = filterValue;

    const body = {
      key: column,
      value: filterValue
    }

    this.filteredData.emit(this.tableDataSource.filter);

    this.searchFilteredData.emit(body); // this is used for search on column
  }

  applyFilter(
    filter: { type: 'string' | 'select'; value: string },
    dataKey: string
  ) {
    this.tableDataSource.filterPredicate = (
      data: any,
      filter: string | string[]
    ) => {
      const value = data[dataKey];
      if (Array.isArray(filter)) {
        return filter.length === 0 || filter.includes(value);
      } else {
        return value.toLowerCase().includes(filter);
      }
    };
    this.tableDataSource.filter = filter.value;
    this.searchFilteredData.emit(this.tableDataSource.filter);
    this.filteredData.emit(this.tableDataSource.filter);
  }

  applySelectFilter(column: TableColumn, event: any) {
    const filterValue = event.value;
    this.filterValues[column.dataKey] = filterValue;

    const filterObject = JSON.parse(this.tableDataSource.filter || '{}');
    filterObject[column.dataKey] = filterValue;

    this.tableDataSource.filterPredicate = (data: any, filter: string) => {
      const selectedValues = Object.values(JSON.parse(filter));
      return (
        !selectedValues.length || selectedValues.includes(data[column.dataKey])
      );
    };

    this.tableDataSource.filter = JSON.stringify(filterObject);
    this.filteredData.emit(this.tableDataSource.filter);
  }

  selectOption(option: Object) {
    this.selectingOptionId.emit(option);
  }
  filterSearch(event:any){
    this.columnSearch.emit(event)
  }

  showFilter(event: Event, item: any) {
    event.stopPropagation();
    let action = item.filter.show;
    this.tableColumns.forEach((col: any) => {
      if (col.filter) {
        col.filter.show = false;
      }
    });
    item.filter.show = !action;
  }

  emitRowAction(row: Object, actionName: string) {
    const data: EventColumn = {
      row,
      actionName,
    };
    this.rowAction.emit(data);
  }

  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource(data);
    if (this.enableSorting) this.tableDataSource.sort = this.matSort;

    // setTimeout(() => {
    //   if (this.isPageableTop || this.isPageableBottom) {
    //     this.tableDataSource.paginator = this.matPaginator;
    //   }
    //   // this.tableDataSource.paginator = this.matPaginator;
    // }, 0);

    // const initialSettingDistrict = this.tableDataSource.filteredData.find(
    //   (item: any) => item.numberOfChristianSeats
    // );
    // if (initialSettingDistrict) {
    //   setTimeout(() => {
    //     if (this.isPageableTop || this.isPageableBottom) {
    //       this.tableDataSource.paginator = this.matPaginator;
    //     }
    //   }, 0);
    // }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.displayedColumns,
      event.previousIndex,
      event.currentIndex
    );
    let sortedColumn: TableColumn[] = [];
    let addedIndex: number[] = [];

    this.displayedColumns.forEach((item) => {
      let index = this.tableColumns.findIndex((c) => c.name == item);
      addedIndex.push(index);
      sortedColumn.push(this.tableColumns[index]);
    });

    for (let i = 0; i < this.tableColumns.length; i++) {
      if (!addedIndex.includes(i)) sortedColumn.push(this.tableColumns[i]);
    }

    this.tableColumns = sortedColumn;
    this.sortedColumn.emit(this.tableColumns);
  }

  openModal(): void {
    const dialogRef = this.dialog.open(CustomColumnModalComponent, {
      width: '80vw',
      height: '80vh',
      data: {
        title: this.translateText('customColumns'),
        message: '',
        tableColumns: this.tableColumns,
        tableData: this.tableDataSource.data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.tableColumns = result;
        this.showColumn();
        this.columnsChange.emit(this.tableColumns);
      }
    });
  }

  showColumn() {
    // initialize filterValues for each column
    this.tableColumns.forEach((column) => {
      this.filterValues[column.dataKey] = '';
    });

    const columnNames: string[] = [];
    this.tableColumns.forEach((tableColumn: TableColumn) => {
      if (tableColumn.show) columnNames.push(tableColumn.name);
    });

    const someCol = this.tableColumns.some((col) => col.show);

    if (this.rowActionIcon && someCol && !this.isCheckbox) {
      this.displayedColumns = [...columnNames, ...this.rowActionIcon];
    } else if (this.rowActionIcon && someCol && this.isCheckbox) {
      this.displayedColumns = [
        ...this.rowActionCheckbox,
        ...columnNames,
        ...this.rowActionIcon,
      ];
    } else {
      this.displayedColumns = columnNames;
    }
  }

  /**
   * Determines whether to show the take action icon based on the status ID.
   * you must initialize the statusesToHideTakeActionIcon array
   * with the status ids that you want to hide the take action icon.
   * @param statusId The ID of the status.
   * @returns A boolean indicating whether to show the take action icon.
   * @author Hamza Al-Zaatrh <hamza.alzaatrh@realsoft-me.com>
   */
  showTakeAction(statusId: number): boolean {
    return !this.statusesToHideTakeActionIcon.includes(statusId);
  }

  clickedOutside(tableColumn: any): void {
    if (tableColumn.filter.show) tableColumn.filter.show = false;
  }

  clearCustomInput() {
    // this.searchForm.get('customInputControl')?.patchValue('');
    this.tableDataSource.filter = '';
  }

  translateText(title: string) {
    return this.translate.instant(`${this.translationPrefix}${title}`);
  }

  rowClick($event: TableColumn) {
    this.rowClickEvent.emit($event);
  }

  onMatCellClick(cellValue: TableColumn, value: number, dataKey?: any) {
    const data = {
      cellValue,
      value,
      dataKey,
    };
    this.sqCellClick.emit(data);
  }

  dataClicked(cellValue: TableColumn, value: number, dataKey?: any) {
    const data = {
      cellValue,
      value,
      dataKey,
    };
    this.linkClicked.emit(data);
  }

  onVerifiedCellClick(cellValue: TableColumn, value: number, dataKey?: any) {
    const data = {
      cellValue,
      value,
      dataKey,
    };
    this.isVerifiedClick.emit(data);
  }

  onSelectionChange(event: any, element: TableColumn) {
    if (event.checked) {
      this.selection.select(element);
    } else {
      this.selection.deselect(element);
    }
    this.selectionChange.emit(this.selection.selected);
  }

  clearSelection() {
    this.selection.clear();
    this.selectionChange.emit(this.selection.selected);
  }


  onSelectionToggleChange(event: any, element: TableColumn) {
    if (event.checked) {
      this.selectionToggle.select(element);
    } else {
      this.selectionToggle.deselect(element);
    }
    this.selectionToggleChange.emit(this.selectionToggle.selected);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableDataSource.data.forEach((row) => this.selection.select(row));
    this.selectionChange.emit(this.selection.selected);
  }

  onRoleSelectionChange(selectedRoles: any) {
    this.selectingRolesChange.emit(selectedRoles);
  }

  onVoterSelectionChange(selectedRoles: any) {
    this.selectingVoterChange.emit(selectedRoles);
  }
  @ViewChild('paginator') paginator: ElementRef;

  onPaginationChange(pagination: any) {
    this.reloadFilter = true
    this.paginationChange.emit(pagination);
  }

  opeCloseFilter() {
    this.isSidePanelFilterClicked.emit(true);
    setTimeout(() => {
      document.body.classList.add('filter-open-close');
    }, 10);
  }

  intiTableFilters() {
    let filterParams: FilterParam[] = this.filterParams;

    this.formService.registerTableFilters(this.filterForm, this.tableDataSource, filterParams, !this.paginated);

  }

}
