<section class="position-absolute not-permission d-flex justify-content-center align-items-center">
    <div class="not-permission__dialog">
       <div class="d-flex justify-content-between align-items-center border-bottom pb-1">
          <p class="m-0 fw-bold">{{ 'general.screenRestricted' | translate }}</p>
          <custom-icon class="position-relative" title="{{ 'general.close' | translate }}" [iconName]="'close'"></custom-icon>
       </div>
       <p class="mb-0 not-permission__dialog--description">
          {{ 'general.screenRestrictedDescription' | translate }}.
       </p>
       <button>{{ 'payment.bigButton2' | translate }}</button>
    </div>
 </section>