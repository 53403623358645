import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, NotAuthenticatedGuard } from '@core/guards';
import { InitialSettingsGuard } from '@core/guards/initialSettings.guard';
import { UserGuard } from '@core/guards/user.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@app/auth/auth.module').then(m => m.AuthModule),
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'campaign',
    loadChildren: () => import('@app/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard, UserGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'initial',
    loadChildren: () =>
      import('@app/initialSettings/initial-settings.module').then(
        m => m.InitialSettingsModule
      ),
    canActivate: [InitialSettingsGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    redirectTo: 'campaign',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'campaign' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
[];
