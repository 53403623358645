import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { PermissionsService } from '@core/services';
import { UserService } from '@core/services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private permissionService: PermissionsService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.userService.getUserPermissions().pipe(
      map(res => {
        const requiredPermission: string[] = route.data['requiredPermission'];
        const listDistrictsType: number = +route.data['listDistrictsType'];
        this.userService.setDistrictsFilterList(listDistrictsType);

        if (requiredPermission) {
          // if required permissions are set, check if any of the required permissions is granted
          if (this.permissionService.hasPermission(requiredPermission)) {
            // if one of the required permissions is granted, return true and allow access
            return true;
          } else {
            // if none of the required permissions is granted, redirect to the dashboard
            this.router.navigate(['campaign/dashboard']);
            return false;
          }
        }
        // if no required permissions are set, allow access
        return true;
      })
    );
  }
}
